<template lang="pug">
.factoring-contract-invoice-list.animated.fadeIn
  .card
    nav.card-header
      b-nav(card-header tabs)
        b-nav-item(:to = '{ name: "FactoringContractInvoiceList" }') {{ $t('menu:factoringInvoices') }}
        b-nav-item(:to = '{ name: "FactoringContractScheduleInvoiceList" }') {{ $t('menu:factoringScheduleInvoices') }}
        b-nav-text.ml-auto.align-items-center.d-inline-flex
          span.toolbar
            button.btn.btn-link.py-0(@click = 'showNewInvoice = true') {{ $t('newLink') }}
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg-6
            range-input-date(
              :options     = 'rangeInputDateOptions'
              :start.sync  = 'searchParams.dateMin'
              :end.sync    = 'searchParams.dateMax'
              :column.sync = 'searchParams.dateColumn'
            )
        .row
          .form-group.col-lg-6
            range-input-text(
              :options     = 'rangeInputTextOptions'
              :start.sync  = 'searchParams.amountMin'
              :end.sync    = 'searchParams.amountMax'
              :column.sync = 'searchParams.amountColumn'
            )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model      = 'invoiceStatusSearchModel'
                :options       = 'invoiceStatusOptions'
                :placeholder = '$t("invoiceStatusesPh")'
                multiple
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-select(
                v-model.number = 'searchParams.schInvoiceStatuses'
                :options       = 'optionsFromClassifier("loanInvoiceStatus", true)'
                :placeholder   = '$t("scheduleStatusesPh")'
              )
      fi-table(
        :fields      = 'fields'
        :items       = 'importedInvoiceList'
        loader       = 'factoring:contract:invoices:imported:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#custom-row)
          tr.animated.fadeIn(v-if = 'selectedItems.length')
            td(:colspan = 'fields.length')
              .btn-toolbar
                .btn-group.mr-2
                  .input-group.input-group-sm
                    fi-select(
                      v-model      = 'newStatus'
                      :placeholder = '$t("selectStatusPh")'
                      :options     = 'availableStatusesOptions'
                      :disabled    = '!availableStatusesOptions.length'
                      required
                    )
                    .input-group-append.input-group
                      button.btn.btn-primary.btn-sm(
                        :disabled = '!newStatus || setStatusLoader'
                        @click    = 'setInvoiceStatus'
                      ) {{ $t('change') }}
                        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'setStatusLoader')
                .btn-group
                  button.btn.btn-warning.btn-sm(
                    :disabled = '!isPayoutPossible'
                    @click    = 'showPayout = true'
                  ) {{ $t('payout') }}
        template(#selectAll = '{ items }')
          b-check(
            :checked       = 'allSelected'
            :indeterminate = 'indeterminate'
            :disabled      = '!items.length'
            @change        = 'toggleSelectAll'
          )
        template(#default = '{ items }')
          factoring-contract-invoice-list-item.animated.fadeIn(
            v-for          = 'invoice in items'
            v-model        = 'selectedItems'
            :key           = 'invoice.factoringInvoiceId'
            :invoice       = 'invoice'
            :third-parties = 'optionsFromThirdParties'
            :fee-configs   = 'feeConfigs'
            @edit          = 'editFactoringInvoice'
            @upload        = 'uploadInvoiceDocuments'
          )
      fi-pagination(
        v-if    = 'importedInvoiceListPages > 1'
        :pages  = 'importedInvoiceListPages'
        v-model = 'pagination.page'
      )
  factoring-contract-invoice-new-modal(
    v-if            = 'showNewInvoice'
    v-model         = 'showNewInvoice'
    loader          = 'factoring:contract:invoice:create'
    :third-parties  = 'optionsFromThirdParties'
    :fee-configs    = 'feeConfigs'
    :processing-fee = 'contractProcessingFee'
    :contract-id    = 'id'
    @submit         = 'addFactoringInvoice'
  )
  factoring-contract-invoice-payout-modal(
    v-if           = 'showPayout'
    v-model        = 'showPayout'
    :invoices.sync = 'payoutInvoices'
    :relations     = 'factoringPaymentRelations'
    :factoring-contract     = 'factoringContractOverview'
    :third-parties = 'factoringThirdParties'
    loader         = 'factoring:invoices:contract:payout:save'
    @submit        = 'doPayout'
  )
</template>


<script>
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import FiPagination from '@/components/FiPagination'
import { mapActions, mapGetters, mapState } from 'vuex'
import FactoringContractInvoiceNewModal from '@/views/factoring/FactoringContractInvoiceNewModal'
import { customerName, getStatuesQuery } from '@/helpers'
import FactoringContractInvoiceListItem from '@/views/factoring/FactoringContractInvoiceListItem'
import intersection from 'lodash/intersection'
import FiFormField from '@/components/FiFormField'
import FactoringContractInvoicePayoutModal from '@/views/factoring/FactoringContractInvoicePayoutModal'
import FiMultiselect from '@/components/FiMultiselect'

const searchParams = {
  criteria: '',
  dateColumn: 'dueDate',
  dateMin: null,
  dateMax: null,
  amountColumn: 'invoiceAmount',
  amountMin: null,
  amountMax: null,
  factoringInvoiceStatus: [],
  factoringInvoiceStatuses: [],
  schInvoiceStatuses: '',
  sort: 'contractId,desc',
  thirdPartiesId: ''
}

export default {
  name: 'factoring-contract-invoice-list',

  components: {
    FactoringContractInvoicePayoutModal,
    FiFormField,
    FactoringContractInvoiceListItem,
    FactoringContractInvoiceNewModal,
    FiPagination,
    FiTable,
    FiSelect,
    RangeInputText,
    RangeInputDate,
    FiMultiselect
  },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data: (vm) => ({
    showPayout: false,
    newStatus: '',
    selectedItems: [],
    showNewInvoice: false,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), searchQuery: vm.criteria, factoringInvoiceStatuses: getStatuesQuery(vm.$route.query.factoringInvoiceStatuses) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('factoring', [
      'factoringContract',
      'importedInvoiceList',
      'importedInvoiceListPages',
      'factoringThirdParties',
      'factoringContractOverview',
      'factoringPaymentRelations'
    ]),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('products', ['optionsFromAllProducts']),
    ...mapState('products', ['feeConfigs']),
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    },
    invoiceStatusOptions () {
      return this.optionsFromClassifier('FactoringImportedInvoiceStatus', true)
    },
    invoiceStatusSearchModel: {
      get () {
        return this.searchParams.factoringInvoiceStatuses.map(searchValue => this.invoiceStatusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.invoiceStatusOptions.length) {
          this.searchParams.factoringInvoiceStatuses = []
        } else {
          this.searchParams.factoringInvoiceStatuses = values.map(({ value }) => value)
        }
      }
    },
    rangeInputDateOptions () {
      return {
        dueDate: this.$t('dueDate'),
        paidOutDate: this.$t('paidOutDate'),
        deadline: this.$t('deadline')
      }
    },
    rangeInputTextOptions () {
      return {
        invoiceAmount: this.$t('invoiceAmount'),
        paidOutAmount: this.$t('paidOutAmount')
      }
    },
    fields () {
      return [
        {
          key: 'selectAll'
        },
        {
          key: 'invoiceNumber',
          label: this.$t('invoiceNumber')
        },
        {
          key: 'thirdParty',
          label: this.$t('thirdParty'),
          sort: 'thirdPartyId'
        },
        {
          key: 'dueDate',
          label: this.$t('dueDate'),
          sort: 'dueDate'
        },
        {
          key: 'invoiceAmount',
          label: this.$t('invoiceAmount'),
          class: 'money'
        },
        {
          key: 'advanceAmount',
          label: this.$t('advanceAmount'),
          class: 'money'
        },
        {
          key: 'paidOutAmount',
          label: this.$t('paidOutAmount'),
          class: 'money'
        },
        {
          key: 'paidOutDate',
          label: this.$t('paidOutDate')
        },
        {
          key: 'factoringInvoiceStatus',
          label: this.$t('factoringInvoiceStatus')
        },
        {
          key: 'reserve',
          label: this.$t('reserve'),
          class: 'money'
        },
        {
          key: 'receivedAmount',
          label: this.$t('receivedAmount'),
          class: 'money'
        },
        {
          key: 'receivableSum',
          label: this.$t('receivableSum'),
          class: 'money'
        },
        {
          key: 'deadline',
          label: this.$t('deadline')
        },
        {
          key: 'paid',
          label: this.$t('paid'),
          class: 'money'
        },
        {
          key: 'paidDate',
          label: this.$t('paidDate')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    searchFilter () {
      const filter = { ...this.searchParams }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      if (!(filter.amountMin || filter.amountMax)) {
        filter.amountColumn = null
      }
      return filter
    },
    allSelected () {
      return this.selectedItems?.length && this.selectedItems?.length === this.importedInvoiceList?.length
    },
    itemsIds () {
      return this.importedInvoiceList.map(({ factoringInvoiceId }) => factoringInvoiceId) || []
    },
    payoutInvoices: {
      get () {
        return this.importedInvoiceList.filter(
          ({ factoringInvoiceId, factoringInvoiceStatus }) => this.selectedItems.includes(factoringInvoiceId) && /pending/i.test(factoringInvoiceStatus.code)
        )
      },
      set (invoices) {
        this.selectedItems = invoices.map(({ factoringInvoiceId }) => factoringInvoiceId) || []
      }
    },
    selectedInvoices: {
      get () {
        return this.importedInvoiceList.filter(({ factoringInvoiceId }) => this.selectedItems.includes(factoringInvoiceId))
      },
      set (invoices) {
        this.selectedItems = invoices.map(({ factoringInvoiceId }) => factoringInvoiceId) || []
      }
    },
    indeterminate () {
      const { length } = this.selectedItems
      return Boolean(length) && (length < this.importedInvoiceList?.length)
    },
    optionsFromThirdParties () {
      return this.factoringThirdParties?.map(thirdParty => ({
        value: thirdParty.id,
        text: `${customerName(thirdParty.customer)} (${thirdParty.customer.idCode})`
      }))
    },
    setStatusLoader () {
      return this.$vueLoading.isLoading('factoring:invoices:imported:status:save')
    },
    availableStatuses () {
      return intersection(
        ...this.importedInvoiceList
          .filter(({ factoringInvoiceId }) => this.selectedItems.includes(factoringInvoiceId))
          .map(({ availableStatuses }) => availableStatuses.map(({ code }) => code) || [])
      )
    },
    isPayoutPossible () {
      return this.selectedInvoices.some(({ factoringInvoiceStatus }) => /pending/i.test(factoringInvoiceStatus.code))
    },
    availableStatusesOptions () {
      return this.optionsFromClassifier('FactoringImportedInvoiceStatus', true).filter(({ value }) => this.availableStatuses.includes(value))
    },
    contractProcessingFee () {
      return this.factoringContract.term.fees.find(({ feeType }) => feeType.code === 'PROCESSING')
    }
  },

  watch: {
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    '$route.query': {
      handler (query) {
        this.loadFactoringImportedInvoices({ ...this.searchQuery, ...query, contractId: this.id })
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    this.loadProducts({ group: 'FACTORING' })
    this.loadFactoringThirdParties({ contractId: this.$route.params.id })
    this.loadFeeConfig({ group: 'FACTORING' })
    this.loadFactoringPaymentRelations({ contractId: this.$route.params.id })
  },

  methods: {
    ...mapActions('factoring', [
      'loadFactoringImportedInvoices',
      'createFactoringInvoice',
      'loadFactoringThirdParties',
      'updateFactoringImportedInvoice',
      'updateFactoringImportedInvoicesStatus',
      'loadFactoringPaymentRelations',
      'createFactoringContractInvoicesPayout',
      'createFactoringImportedInvoiceDocument'
    ]),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    ),
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.itemsIds
      } else {
        this.selectedItems = []
      }
    },
    async addFactoringInvoice ({ invoiceData, resourceData, files, done }) {
      await this.createFactoringInvoice({ invoiceData, resourceData, files })
      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    async editFactoringInvoice ({ invoiceData, invoiceId, done }) {
      await this.updateFactoringImportedInvoice({ invoiceId, invoiceData })
      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    async doPayout ({ done, payoutData }) {
      await this.createFactoringContractInvoicesPayout({ contractId: this.id, payoutData })
      // eslint-disable-next-line no-unused-expressions
      done?.()
      this.selectedItems = []
    },
    async setInvoiceStatus () {
      let isError
      this.selectedItems.forEach(element => {
        this.importedInvoiceList.forEach(invoice => {
          if (invoice.factoringInvoiceId === element) {
            this.factoringThirdParties.forEach(thirdParty => {
              if (thirdParty.id === invoice.thirdPartyId) {
                if (thirdParty.endDate) {
                  if (new Date() > new Date(thirdParty.endDate)) {
                    this.$toasted.error('The end date of the third party agreement is in the past.')
                    isError = 'Error'
                  }
                }
              }
            })
          }
        })
      })
      if (isError === 'Error') return
      await this.updateFactoringImportedInvoicesStatus({ status: this.newStatus, invoices: this.selectedItems })
      this.selectedItems = []
      this.newStatus = ''
    },
    async uploadInvoiceDocuments ({ done, ...payload }) {
      await this.createFactoringImportedInvoiceDocument(payload)
      // eslint-disable-next-line no-unused-expressions
      done?.()
    }
  }
}
</script>


<i18n>
en:
  newLink:                "+ New invoice"
  searchPlaceholder:      "Search by third-party name"
  invoiceStatusesPh:      "All invoice statuses"
  scheduleStatusesPh:     "All schedule statuses"
  invoiceNumber:          "Invoice #"
  thirdParty:             "Third-party"
  notFound:               "Invoices not found"
  dueDate:                "Due date"
  invoiceAmount:          "Invoice amount"
  advanceAmount:          "Advance amount"
  paidOutAmount:          "Paid out amount"
  paidOutDate:            "Paid out date"
  factoringInvoiceStatus: "Invoice status"
  reserve:                "Reserve amount"
  receivedAmount:         "Received amount"
  receivableSum:          "Receivable sum"
  deadline:               "Deadline"
  paid:                   "Paid amount"
  paidDate:               "Paid date"
  status:                 "Schedule status"
  recourse:               "Recourse"
  selectStatusPh:         "Set status to..."
  payout:                 "Payout"
  change:                 "Change"
et:
  newLink:                "+ New invoice"
  searchPlaceholder:      "Search by third-party name"
  invoiceStatusesPh:      "All invoice statuses"
  scheduleStatusesPh:     "All schedule statuses"
  invoiceNumber:          "Invoice #"
  thirdParty:             "Third-party"
  notFound:               "Invoices not found"
  dueDate:                "Due date"
  invoiceAmount:          "Invoice amount"
  advanceAmount:          "Advance amount"
  paidOutAmount:          "Paid out amount"
  paidOutDate:            "Paid out date"
  factoringInvoiceStatus: "Invoice status"
  reserve:                "Reserve amount"
  receivedAmount:         "Received amount"
  receivableSum:          "Receivable sum"
  deadline:               "Deadline"
  paid:                   "Paid amount"
  paidDate:               "Paid date"
  status:                 "Schedule status"
  recourse:               "Recourse"
  selectStatusPh:         "Set status to..."
  payout:                 "Payout"
  change:                 "Change"
ru:
  newLink:                "+ New invoice"
  searchPlaceholder:      "Search by third-party name"
  invoiceStatusesPh:      "All invoice statuses"
  scheduleStatusesPh:     "All schedule statuses"
  invoiceNumber:          "Invoice #"
  thirdParty:             "Third-party"
  notFound:               "Invoices not found"
  dueDate:                "Due date"
  invoiceAmount:          "Invoice amount"
  advanceAmount:          "Advance amount"
  paidOutAmount:          "Paid out amount"
  paidOutDate:            "Paid out date"
  factoringInvoiceStatus: "Invoice status"
  reserve:                "Reserve amount"
  receivedAmount:         "Received amount"
  receivableSum:          "Receivable sum"
  deadline:               "Deadline"
  paid:                   "Paid amount"
  paidDate:               "Paid date"
  status:                 "Schedule status"
  recourse:               "Recourse"
  selectStatusPh:         "Set status to..."
  payout:                 "Payout"
  change:                 "Change"
</i18n>
