<template lang="pug">
.card.factoring-contract-invoices-overview
  b-tabs(
    content-class = 'border-top border-0'
    card
    lazy
  )
    b-tab(:title = '$t("invoices")')
      factoring-contract-overview-invoices-list(:invoices = 'contractInvoices')
    b-tab(:title = '$t("schedule")')
      factoring-contract-overview-schedule(:invoices = 'scheduleInvoices')
</template>


<script>
import FactoringContractOverviewInvoicesList from '@/views/factoring/FactoringContractOverviewInvoicesList'
import FactoringContractOverviewSchedule from '@/views/factoring/FactoringContractOverviewScheduleInvoiceList'

export default {
  name: 'factoring-contract-invoices-overview',

  components: { FactoringContractOverviewSchedule, FactoringContractOverviewInvoicesList },

  props: {
    scheduleInvoices: {
      type: Array,
      required: true
    },
    contractInvoices: {
      type: Array,
      required: true
    }
  }
}
</script>


<i18n>
en:
  invoices: "Factoring invoices"
  schedule: "Schedule"
et:
  invoices: "Factoring invoices"
  schedule: "Schedule"
ru:
  invoices: "Factoring invoices"
  schedule: "Schedule"
</i18n>
