<template lang="pug">
.factoring-view
  v-loading(loader='factoring:contract:fetch')
    template(#spinner)
      .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
    .animated.fadeIn(v-if = 'factoringContract')
      .factoring-view-header
        h3.header
          .header-item: router-link(:to = '{ name: "Customer", params: { id: factoringContract.customerBorrowerId.toString() } }') {{ fullName }}
          .header-item: router-link(:to = '{  name: "FactoringContract", params: { id: this.id.toString() }}')
            v-loading.d-inline-block(loader = 'factoring:product:fetch')
              template(#spinner)
                i.fa.fa-spinner.fa-pulse.fa-lg
              template(v-if = 'factoringProduct') {{ factoringProduct.name }} ({{ factoringContract.agreementNumber }})
          .header-item: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
        .row
          .col
            ul.nav.nav-pills.mt-2.mb-2
              li.nav-item: router-link.nav-link(:to = '{ name: "FactoringOverview" }') {{ $t('overview') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "FactoringTerms" }') {{ $t('terms') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "FactoringInvoices" }') {{ $t('invoices') }}
              template(v-if = 'isShowFinancials')
                li.nav-item: router-link.nav-link(:to = '{ name: "FactoringFinancials" }') {{ $t('financials') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "FactoringCommunication" }') {{ $t('communication') }}
              li.nav-item: router-link.nav-link(v-if = 'factoringContract.applicationId'
                :to  = '{ name: "Application", params: { id: factoringContract.applicationId } }') {{ $t('application') }}
          v-loading.col-auto(loader = 'loan:product:fetch')
            i.fa.fa-spinner.fa-pulse.fa-lg(slot = 'spinner')
            .btn-toolbar
              .btn-group
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("NotificationEmailTemplate")')
                  i.far.fa-bell.fa-fw
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("ContractEmailTemplate")')
                  i.far.fa-envelope.fa-fw
                  |&nbsp;
                  i.fa.fa-print.fa-fw
      router-view

      loan-send-message-modal(
        v-model        = 'showSendMessageModal'
        :contract      = 'factoringContract'
        :product       = 'factoringProduct'
        :customer      = 'factoringCustomer'
        :type          = 'templateType'
        :third-parties = 'factoringThirdParties'
        :schedules     = 'factoringContractTerms'
        :schedule      = 'factoringContract.term'
        store-module   = 'factoring'
      )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { customerName } from '@/helpers'
import { loanStatuses } from '@/const'
import LoanSendMessageModal from '@/views/loans/LoanSendMessageModal'

export default {
  name: 'factoring-view',

  components: { LoanSendMessageModal },

  props: {
    id: {
      type: [Number, String],
      default: undefined
    }
  },

  data: () => ({
    showSendMessageModal: false,
    templateType: ''
  }),

  computed: {
    ...mapState('factoring', ['factoringContractTerms', 'factoringContract', 'factoringProduct', 'factoringCustomer', 'factoringThirdParties']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('customers', ['customerName']),
    status () {
      const { classifier, code } = this.factoringContract?.status || {}
      return this.classifierByName(classifier, code) ?? {}
    },
    fullName () {
      return `${customerName(this.factoringCustomer)} (${this.factoringCustomer?.idCode})`
    },
    isShowFinancials () {
      const { active, matured, terminated, delayed, bankrupt, collector, deleted, complete, bailiff, conkedOut, breached, closed, agreed, writtenOff } = loanStatuses
      return [active, matured, terminated, delayed, bankrupt, collector, deleted, complete, bailiff, conkedOut, breached, closed, agreed, writtenOff].includes(this.factoringContract?.status?.code)
    }
  },

  async created () {
    this.loadFactoringClassifiers()
    this.loadFactoringInvoiceClassifiers()
    await this.loadFactoringContract({ id: this.id })
    await this.loadFactoringFinancial({ contractId: this.id })
    this.loadProduct({ id: this.factoringContract.productId })
    this.loadFactoringContractTerms({ id: this.id })
    await this.loadFactoringCustomer({ id: this.factoringContract.customerBorrowerId })
    this.loadCustomerRelations({ customerId: this.factoringCustomer.id })
    await this.loadFactoringThirdParties({ contractId: this.factoringContract?.id })
    await this.loadFactoringContractOverview({ id: this.$route.params?.id })
  },

  methods: {
    ...mapActions('factoring', [
      'loadFactoringContract',
      'loadProduct',
      'loadFactoringCustomer',
      'loadFactoringContractTerms',
      'loadCustomerRelations',
      'loadFactoringFinancial',
      'loadFactoringThirdParties',
      'loadFactoringContractOverview'
    ]),
    ...mapActions('classifiers', ['loadFactoringClassifiers', 'loadFactoringInvoiceClassifiers']),
    showSendMessage (type) {
      this.templateType = type
      this.showSendMessageModal = true
    }
  }
}
</script>


<i18n>
en:
  overview:      "Overview"
  terms:         "Terms"
  invoices:      "Invoices"
  communication: "Communication"
  application:   "Application"
  financials:    "Financials"
et:
  overview:      "Overview"
  terms:         "Terms"
  invoices:      "Invoices"
  communication: "Communication"
  application:   "Application"
  financials:    "Financials"
ru:
  overview:    "Overview"
  terms:       "Terms"
  invoices:    "Invoices"
  communication: "Communication"
  application:   "Application"
  financials:    "Financials"
</i18n>
