<template lang="pug">
b-modal.factoring-contract-invoice-details-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title", { status: status.human })'
  size    = 'xl'
  lazy
)
  .row
    .col-lg-6
      fi-data-field(:label = '$t("thirdParty")') {{ factoringImportedInvoice.thirdPartyName }}
      fi-data-field(:label = '$t("invoiceNr")') {{ factoringImportedInvoice.invoiceNumber }}
      fi-data-field(:label = '$t("amount")') {{ factoringImportedInvoice.amount | money }}
      fi-data-field(:label = '$t("dueDate")') {{ $t('common:formatDate', { value: factoringImportedInvoice.dueDate }) }}
      fi-data-field(:label = 'classifierByName("feeTypeEntity", processingFee.feeType.code).human')
        template(v-if = 'percentageProcessingFee') {{ processingFee.feeAmount | percent }}
        template(v-else = 'percentageProcessingFee') {{ processingFee.feeAmount | money }}
    .col-lg-6
      fi-data-field(:label = '$t("advanceRate")') {{ factoringImportedInvoice.advanceRate | percent }}
      fi-data-field(:label = '$t("advanceAmount")') {{ factoringImportedInvoice.advanceAmount | money }}
      fi-data-field(:label = '$t("availableLimit")') {{ factoringImportedInvoice.availableLimit | money }}
      fi-data-field(:label = '$t("invoiceDate")') {{ $t('common:formatDate', { value: factoringImportedInvoice.invoiceDate }) }}
      fi-data-field(:label = '$t("recourse")')
        fi-switch(
          v-model = 'factoringImportedInvoice.recourse'
          read-only
        )
  template(#modal-footer)
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:close") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import FiDataField from '@/components/FiDataField'
import { feePercentage } from '@/helpers'

export default {
  name: 'factoring-contract-invoice-details-modal',

  components: { FiDataField, FiSwitch, FiModal },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    invoiceId: {
      type: [Number, String],
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'optionsFromClassifier']),
    ...mapState('factoring', ['factoringImportedInvoice']),
    processingFee () {
      return this.factoringImportedInvoice?.processingFee ?? { feeType: {} }
    },
    showModal: {
      get () {
        return this.value && !this.loader
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    status () {
      return this.classifierById(this.factoringImportedInvoice?.status?.classifier, this.factoringImportedInvoice?.status?.code)
    },
    percentageProcessingFee () {
      return feePercentage(this.processingFee.feeCalculationType?.code)
    }
  },

  async created () {
    await this.loadFactoringImportedInvoice({ invoiceId: this.invoiceId })
  },

  methods: {
    ...mapActions('factoring', ['loadFactoringImportedInvoice']),
    async onSubmit () {
      this.$emit('submit', {
        invoiceId: this.invoiceId,
        invoiceData: this.invoiceData,
        done: () => this.$refs.modal.hide()
      })
    }
  }
}
</script>


<i18n>
en:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
et:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
ru:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
</i18n>
