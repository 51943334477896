<template lang='pug'>
b-modal.factoring-financials-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title", { accountType })'
  size    = 'xl'
  lazy
  @shown  = 'resetData'
)
  .row
    .col-4(v-if = 'thirdParties')
      fi-form-field(
        :label = '$t("thirdParty")'
        label-cols="3"
      )
        fi-select(
          v-model  = 'thirdPartyId'
          :options = 'thirdParties'
        )
    .col.ml-auto
      button.btn.btn-primary.mb-3.fa-pull-right(
        v-if           = 'provision'
        :disabled      = '!transactionState.length || !thirdPartyBased'
        @click.prevent = 'showProvisionRow = true'
      ) {{ $t('writeOff')}}
      |&nbsp;
      button.btn.btn-primary.mb-3.fa-pull-right(
        v-if           = 'correction'
        :disabled      = '!thirdPartyBased'
        @click.prevent = 'showCorrectionRow = true'
      ) {{ $t('addCorrection')}}

  fi-table(
    :fields     = 'isDebt ? fieldsDebt : fields'
    :items      = 'transactionState'
    :loader     = 'loader'
    :emptyLabel = '$t("notFound")'
    responsive
    @sort       = 'setSort'
  )
    template(slot = 'custom-row')
      tr.animated.fadeIn(v-if = 'showProvisionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td {{ $t('credit') }}
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(
              v-model        = '$v.provisionData.amount.$model'
              :class         = '{ "is-invalid": $v.provisionData.amount.$error }'
              :decimal-limit = 6
              autofocus
           )
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.comment.$error')
            input.form-control.form-control-sm.mr-2(
              v-model = '$v.provisionData.comment.$model'
              :class  = '{ "is-invalid": $v.provisionData.comment.$error }'
            )
        td {{ provisionAccount.human }}
        td {{ classifierByName('accountTypes', 'SYSTEM').human }}
          .btn-toolbar.fa-pull-right
            .btn-group-sm.btn-group
              button.btn.btn-primary.btn-sm(@click.prevent = 'saveProvision') {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse(v-if = 'provisioning')
              button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}
      tr.animated.fadeIn(v-if = 'showCorrectionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td
          fi-switch(
            v-model   = 'correctionData.isDebit'
            :on-text  = '$t("debit")'
            :off-text = '$t("credit")'
          )
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(
              v-model        = '$v.correctionData.amount.$model'
              :class         = '{ "is-invalid": $v.correctionData.amount.$error }'
              :decimal-limit = 6
              autofocus
            )
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.comment.$error')
            input.form-control.form-control-sm.mr-2(
              v-model = '$v.correctionData.comment.$model'
              :class  = '{ "is-invalid": $v.correctionData.comment.$error }'
            )
        td(colspan = 2)
          .btn-toolbar.fa-pull-right
            .btn-group-sm.btn-group
              button.btn.btn-primary.btn-sm(@click.prevent = 'saveCorrection') {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse(v-if = 'correcting')
              button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}

    template(v-if = '!isDebt' v-slot = '{ items }')
      loan-financials-transaction-list-item(
        v-for        = 'transaction in items'
        :key         = 'transaction.id'
        :transaction = 'transaction'
      )
    template(v-else v-slot = '{ items }')
      loan-financials-transaction-debts-list-item(
        v-for        = 'transaction in items'
        :key         = 'transaction.id'
        :transaction = 'transaction'
      )
  fi-pagination(
    v-if    = 'transactionsPages > 1'
    :pages  = 'transactionsPages'
    v-model = 'pagination.page'
  )
  template(slot = 'modal-footer')
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:close") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSwitch from '@/components/FiSwitch'
import FiTable from '@/components/FiTable'
import LoanFinancialsTransactionListItem from '@/views/loans/LoanFinancials/LoanFinancialsTransactionListItem'
import LoanFinancialsTransactionDebtsListItem from '@/views/loans/LoanFinancials/LoanFinancialsTransactionDebtsListItem'
import FiPagination from '@/components/FiPagination'
import { mapActions, mapGetters, mapState } from 'vuex'
import { validation, validators } from '@/validation/index'
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'factoring-financials-list-modal',

  components: {
    FiSelect,
    FiModal,
    FiSwitch,
    FiMoneyInput,
    FiFormField,
    FiPagination,
    LoanFinancialsTransactionListItem,
    LoanFinancialsTransactionDebtsListItem,
    FiTable
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountType: {
      type: [String, Array],
      default: ''
    },
    totalDebt: {
      type: [Number, String],
      default: ''
    },
    transactionState: {
      type: [Array, Function],
      required: true
    },
    transactionsPages: {
      type: [Number, Function],
      default: 0
    },
    loader: {
      type: String,
      required: true
    },
    provision: {
      type: Boolean,
      default: false
    },
    correction: {
      type: Boolean,
      default: false
    },
    thirdParties: {
      type: Array,
      default: null
    },
    isDebt: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      provisionData: {
        amount: this.totalDebt,
        comment: null,
        accountType: null
      },
      correctionData: {
        amount: null,
        comment: null,
        accountType: null,
        isDebit: false
      },
      showProvisionRow: false,
      showCorrectionRow: false,
      thirdPartyId: '',
      pagination: {
        sort: 'id,desc',
        size: 25,
        page: 0
      }
    }
  },

  computed: {
    ...mapState('loans', ['systemData']),
    ...mapGetters('classifiers', ['classifierByName']),
    provisioning () {
      return this.$vueLoading.isLoading(`contract:${this.$route.params.id}:provision:save`)
    },
    correcting () {
      return this.$vueLoading.isLoading(`contract:${this.$route.params.id}:correction:save`)
    },
    currentAccount () {
      return this.classifierByName('accountTypes', [
        'LEDGER_PRINCIPAL_RECEIVABLE',
        'LEDGER_INTEREST_RECEIVABLE',
        'LEDGER_PENALTY_RECEIVABLE',
        'LEDGER_FEE_RECEIVABLE',
        'LEDGER_VAT_RECEIVABLE',
        'LEDGER_VAT_TAX_RECEIVABLE'
      ].find(type => Array.isArray(this.accountType) && this.accountType.includes(type)) || this.accountType) ?? {}
    },
    provisionAccount () {
      return this.classifierByName('accountTypes', [
        'LEDGER_FEE_RECEIVABLE'
      ].find(type => (Array.isArray(this.accountType) && this.accountType.includes(type)) || this.accountType)) ?? {}
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'time',
          label: this.$t('date'),
          sort: 'time'
        },
        {
          key: 'dc',
          label: this.$t('dc')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'comment',
          label: this.$t('comment')
        },
        {
          key: 'accountFrom',
          label: this.$t('accountFrom')
        },
        {
          key: 'accountTo',
          label: this.$t('accountTo')
        }
      ]
    },
    fieldsDebt () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'time',
          label: this.$t('date'),
          sort: 'time'
        },
        {
          key: 'dc',
          label: this.$t('dc')
        },
        ...this.vat ? [{
          key: 'vat',
          label: this.$t('vat'),
          class: 'money'
        }] : [],
        {
          key: 'amount',
          // label: this.$t('amount'),
          label: 'Fee amount',
          class: 'money'
        },
        {
          key: 'feeName',
          label: 'Fee name'
        },
        // {
        //   key: 'feeAmount',
        //   label: 'Fee amount'
        // },
        {
          key: 'comment',
          label: this.$t('comment')
        },
        {
          key: 'accountFrom',
          label: this.$t('accountFrom')
        },
        {
          key: 'accountTo',
          label: this.$t('accountTo')
        }
      ]
    },
    thirdPartyBased () {
      return !this.thirdParties || this.thirdPartyId
    }
  },

  watch: {
    value (value) {
      if (!value) return
      this.search()
    },
    'pagination.page': 'search',
    thirdPartyId: 'search'
  },

  validations () {
    return {
      provisionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showProvision && this.showProvisionRow
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showProvision && this.showProvisionRow
          })
        }
      },
      correctionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showCorrection && this.showCorrectionRow
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showCorrection && this.showCorrectionRow
          })
        }
      }
    }
  },

  methods: {
    ...mapActions('factoring', ['saveLoanProvision', 'saveLoanCorrection']),
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.provisionData.accountType = this.correctionData.accountType = this.currentAccount.code
    },
    search () {
      this.$emit('search', { filters: { ...this.pagination, thirdPartyId: this.thirdPartyId } })
    },
    async saveProvision () {
      if (this.validate()) {
        await this.saveLoanProvision({ id: this.$route.params.id, provisionData: { ...this.provisionData, thirdPartyId: this.thirdPartyId } })
        this.resetData()
      }
    },
    async saveCorrection () {
      if (this.validate()) {
        await this.saveLoanCorrection({ id: this.$route.params.id, correctionData: { ...this.correctionData, thirdPartyId: this.thirdPartyId } })
        this.resetData()
      }
    },
    setSort (sort) {
      this.searchParams.sort = sort
    }
  }
}
</script>


<i18n>
en:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
  thirdParty:    "Third party"
et:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
  thirdParty:    "Third party"
ru:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
  thirdParty:    "Third party"
</i18n>
