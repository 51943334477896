<template lang="pug">
.factoring-new-third-party-list.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.py-0.animated.fadeIn(
      :title         = '$t("common:add")'
      @click.prevent = 'showAddThirdParty = true'
    ): i.fas.fa-user-plus.fa-lg
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'thirdPartyList'
      :empty-label = '$t("notFound")'
    )
      template(#default = '{ items }')
        factoring-new-third-party-list-item(
          v-for        = '(item, index) in items'
          :key         = 'index'
          :product     = 'product'
          :contract    = 'sourceData'
          :third-party = 'item'
          @update      = '$listeners.update'
          @edit        = '$listeners.update(index, $event)'
          @delete      = '$listeners.update(index)'
        )
  factoring-new-third-party-modal(
    v-model      = 'showAddThirdParty'
    :product     = 'product'
    :source-data = 'sourceData'
    :loader      = '$vueLoading.isLoading(`application:${sourceData.id}:offerTerms:save`)'
    @update      = '$listeners.update(undefined, $event)'
  )
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringNewThirdPartyListItem from '@/views/factoring/FactoringNew/FactoringNewThirdPartyListItem'
import FactoringNewThirdPartyModal from '@/views/factoring/FactoringNew/FactoringNewThirdPartyModal'

export default {
  name: 'factoring-new-third-party-list',

  components: { FactoringNewThirdPartyModal, FactoringNewThirdPartyListItem, FiTable },

  props: {
    thirdPartyList: {
      type: Array,
      default: () => []
    },
    sourceData: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    showAddThirdParty: false
  }),

  computed: {
    fields () {
      return [
        {
          key: 'customerName',
          label: this.$t('customerName')
        },
        {
          key: 'limit',
          label: this.$t('limit'),
          class: 'money'
        },
        {
          key: 'advanceRate',
          label: this.$t('advanceRate')
        },
        {
          key: 'invoiceLength',
          label: this.$t('invoiceLength')
        },
        {
          key: 'interestRate',
          label: this.$t('interestRate')
        },
        {
          key: 'startDate',
          label: this.$t('startDate')
        },
        {
          key: 'endDate',
          label: this.$t('endDate')
        },
        {}
      ]
    }
  }
}
</script>


<i18n>
en:
  title:         "Third parties"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  startDate:     "Start date"
  endDate:       "End date"
  notFound:      "No third parties"
et:
  title:         "Third parties"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  startDate:     "Start date"
  endDate:       "End date"
  notFound:      "No third parties"
ru:
  title:         "Third parties"
  customerName:  "Customer name"
  limit:         "Limit"
  advanceRate:   "Advance rate"
  invoiceLength: "Invoice length (days)"
  interestRate:  "Interest rate"
  startDate:     "Start date"
  endDate:       "End date"
  notFound:      "No third parties"
</i18n>
