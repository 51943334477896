<template lang="pug">
tr.factoring-new-third-party-list-item
  td {{ thirdParty.customerName }}
  td.money {{ thirdParty.limit |money }}
  td {{ thirdParty.advanceRate | percent }}
  td {{ thirdParty.invoiceLengthMin }} - {{ thirdParty.invoiceLengthMax }}
  td
    .row(v-for = 'interestRate in thirdParty.invoiceLengthInterestRates')
      .col-4 {{ interestRate.invoiceLength }} {{ $t('days') }}
      .col-4 {{ interestRate.periodInterestRate | percent }}
      .col-4 {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
  td {{ $t('common:formatDate', { value: thirdParty.startDate }) }}
  td {{ $t('common:formatDate', { value: thirdParty.endDate }) }}
  td
    .btn-toolbar
      .btn-group.btn-group-sm
        button.btn.btn-link(@click.prevent = 'showEditThirdParty = true')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link(@click.prevent = '$emit("delete")')
          i.far.fa-trash-alt.fa-lg.text-danger
  factoring-new-third-party-modal(
    v-model      = 'showEditThirdParty'
    :product     = 'product'
    :source-data = 'contract'
    :third-party = 'thirdParty'
    :loader      = 'loader'
    @update      = '$listeners.edit'
  )
</template>


<script>
import FactoringNewThirdPartyModal from '@/views/factoring/FactoringNew/FactoringNewThirdPartyModal'

export default {
  name: 'factoring-new-third-party-list-item',

  components: { FactoringNewThirdPartyModal },

  props: {
    thirdParty: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    contract: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showEditThirdParty: false
  }),

  computed: {
    loader () {
      return this.$vueLoading.isLoading(`factoringContract:${this.contract.id}:thirdParty:save`)
    }
  }
}
</script>
