<template lang="pug">
.factoring-contract-new.animated.fadeIn
  form.form-horizontal(
    novalidate
    @submit.prevent = 'createContract'
  )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('menu:factoringNew') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(
              :label = '$t("customer")'
              :state = '!$v.factoringData.customerBorrowerId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.factoringData.customerBorrowerId.required') {{ $t('common:required') }}
              fi-customer-select(
                v-model      = 'selectedCustomerModel'
                :sector-id   = 'factoringProduct.sectorId'
                :placeholder = '$t("customerPh")'
                :class       = '{ "is-invalid": $v.factoringData.customerBorrowerId.$error }'
              )
            fi-form-field(
              :label = '$t("product")'
              :state = '!$v.factoringData.productId.$error'
            )
              v-loading(loader = 'products:fetch')
                .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
                template(#error)
                  .error-message(v-if = '!$v.factoringData.productId.required') {{ $t('common:required') }}
                fi-select.animated.fadeIn(
                  v-model.number = '$v.factoringData.productId.$model'
                  :options       = 'productsBySector'
                  :placeholder   = '$t("productPh")'
                  :class         = '{ "is-invalid": $v.factoringData.productId.$error }'
                  required
                  sm
                )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('factoringData') }}
      v-loading(loader = 'factoring:product:fetch')
        .text-center.animated.fadeIn(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-3
        .card-body.animated.fadeIn
          .row
            .col-lg-6
              fi-form-field(
                :label = '$t("startDate")'
                :state = '!$v.factoringTerms.periodStart.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.factoringTerms.periodStart.required') {{ $t('common:required') }}
                fi-datepicker(
                  v-model   = '$v.factoringTerms.periodStart.$model'
                  :class    = '{ "is-invalid": $v.factoringTerms.periodStart.$error }'
                  :min-date = 'systemData.nextDayChange'
                  sm
                )

              fi-form-field(
                :label = '$t("endDate")'
                :state = '!$v.factoringTerms.periodEnd.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.factoringTerms.periodEnd.required') {{ $t('common:required') }}
                fi-datepicker(
                  v-model         = '$v.factoringTerms.periodEnd.$model'
                  :class          = '{ "is-invalid": $v.factoringTerms.periodEnd.$error }'
                  :disabled-dates = 'lastMonthDays'
                  :min-date       = 'periodMinDate'
                  :max-date       = 'periodMaxDate'
                  sm
                )
              fi-form-field(
                :label = '$t("contractLimit")'
                :state = '!$v.factoringTerms.contractLimit.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.factoringTerms.contractLimit.required') {{ $t('common:required') }}
                fi-money-input.form-control.form-control-sm(
                  v-model = '$v.factoringTerms.contractLimit.$model'
                  :class  = '{ "is-invalid": $v.factoringTerms.contractLimit.$error }'
                )
              fi-form-field(
                :label = '$t("advanceRate")'
                :state = '!$v.factoringTerms.advanceRate.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.factoringTerms.advanceRate.required') {{ $t('common:required') }}
                percent-input.form-control.form-control-sm(
                  v-model = '$v.factoringTerms.advanceRate.$model'
                  :class  = '{ "is-invalid": $v.factoringTerms.advanceRate.$error }'
                )
              fi-form-field(
                :label = '$t("invoiceLength")'
                :state = '!$v.factoringTerms.invoiceLengthGroup.$error'
              )
                template(#error)
                  .row
                    .col
                      .error-message(v-if = '!$v.factoringTerms.invoiceLengthMin.required') {{ $t('common:required') }}
                    .col
                      .error-message(v-if = '!$v.factoringTerms.invoiceLengthMax.required') {{ $t('common:required') }}
                .row
                  .input-group.col.input-group-sm
                    .input-group-prepend
                      span.input-group-text {{ $t('common:min') }}
                    input.form-control.form-control-sm.animated.fadeIn(
                      type           = 'text'
                      v-model.number = '$v.factoringTerms.invoiceLengthMin.$model'
                      :class         = '{ "is-invalid": $v.factoringTerms.invoiceLengthMin.$error }'
                    )
                  .input-group.col.input-group-sm
                    .input-group-prepend
                      span.input-group-text {{ $t('common:max') }}
                    input.form-control.form-control-sm.animated.fadeIn(
                      type           = 'text'
                      v-model.number = '$v.factoringTerms.invoiceLengthMax.$model'
                      :class         = '{ "is-invalid": $v.factoringTerms.invoiceLengthMax.$error }'
                    )
              fi-form-field(:label = '$t("interestRate")')
                application-factoring-interest-rate-item(
                  v-for                 = '(interestRate, index) in factoringTerms.invoiceLengthInterestRates'
                  :key                  = 'index'
                  :interest-rate        = 'interestRate'
                  :day-count-convention = 'productInterestRate.dayCountConvention'
                  @input                = 'updateInterestRate($event, index)'
                )
                  .col-2.px-1
                    button.btn.btn-danger.btn-sm(
                      v-if           = 'index'
                      @click.prevent = 'updateInterestRate(undefined, index)'
                    ): i.far.fa-trash-alt.fa-lg
                    button.btn.btn-primary.btn-sm(
                      v-else
                      @click.prevent = 'addInterestRate'
                    ): i.far.fa-plus-square.fa-lg
              fi-form-field(
                :label = '$t("payoutBankAccount")'
                :state = '!$v.factoringTerms.payoutBankAccount.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.factoringTerms.payoutBankAccount.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.number = 'factoringTerms.payoutBankAccount'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.factoringTerms.payoutBankAccount.$error }'
                )
            .col-lg-6
              fi-form-field(:label = '$t("penaltyRate")')
                percent-input.form-control.form-control-sm(v-model = 'factoringTerms.penaltyRate')
              fi-form-field(
                v-for  = '(fee, index) in factoringTerms.fees'
                :key   = 'index'
                :label = 'classifierByCode("feeTypeEntity", fee.feeType).human'
              )
                .form-row
                  .col: fi-select(
                    v-model.number = 'fee.feeCalculationType'
                    :options       = 'feeCalculationTypeOptions[fee.feeType]'
                    required
                    sm
                  )
                  .col
                    percent-input.form-control.form-control-sm(
                      v-if           = 'feePercentage(fee.feeCalculationType)'
                      v-model.number = 'fee.feeAmount'
                      type           = 'text'
                    )
                    fi-money-input.form-control.form-control-sm(
                      v-else
                      v-model.number = 'fee.feeAmount'
                      type           = 'text'
                    )
              fi-form-field(:label = '$t("rightOfRecourse")')
                fi-switch(v-model = 'factoringTerms.rightOfRecourse')
              fi-form-field(:label = '$t("specialTerms")')
                fi-rich-text-editor(v-model.trim = 'factoringTerms.specialTerms')

    factoring-new-third-party-list.mb-0.border-bottom-0(
      :source-data      = 'factoringTerms'
      :product          = 'factoringProduct'
      :third-party-list = 'thirdParties'
      @update           = 'updateThirdParty'
    )
    .card
      .card-body
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'onCancel'
        ) {{ $t("common:cancel") }}
</template>


<script>
import { validation, validators } from '@/validation'
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiDatepicker from '@/components/FiDatepicker'
import { excludeLastMonthDays, isFeePercentage } from '@/mixins'
import { periodUnit } from '@/const'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import FiSwitch from '@/components/FiSwitch'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import FactoringNewThirdPartyList from '@/views/factoring/FactoringNew/FactoringNewThirdPartyList'
import { calculationTypes } from '@/helpers'

export default {
  name: 'factoring-contract-new',

  components: { FactoringNewThirdPartyList, FiCustomerSelect, FiRichTextEditor, FiSwitch, ApplicationFactoringInterestRateItem, PercentInput, FiMoneyInput, FiDatepicker, FiSelect, FiMultiselect, FiFormField },

  mixins: [validation, excludeLastMonthDays, isFeePercentage],

  props: {
    customer: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    selectedCustomer: null,
    factoringData: {
      customerBorrowerId: '',
      productId: ''
    },
    factoringTerms: {
      advanceRate: null,
      comment: null,
      contractLimit: null,
      fees: [],
      invoiceLengthInterestRates: [],
      invoiceLengthMax: null,
      invoiceLengthMin: null,
      payoutBankAccount: null,
      penaltyRate: null,
      periodEnd: undefined,
      periodStart: new Date(),
      rightOfRecourse: true,
      specialTerms: null
    },
    thirdParties: []
  }),

  computed: {
    ...mapGetters('factoring', ['factoringProductAttributes']),
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('products', ['optionsFromProductsBySectorAndSegment']),
    ...mapState('factoring', ['factoringProduct', 'factoringContract']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByCode', 'classifierList']),
    ...mapState('loans', ['systemData']),
    ...mapGetters('settings', ['settingByKey']),
    saving () {
      return this.$vueLoading.isLoading('factoring:contract:save')
    },
    // TODO Move `productsBySector` to somewhere common place
    productsBySector () {
      const { sectorId, segmentId } = this.selectedCustomerModel || {}
      return this.optionsFromProductsBySectorAndSegment({ sectorId, segmentId })
    },
    periodMinDate () {
      const { minValue, periodUnitType } = this.factoringProductAttributes?.AGREEMENT_LENGTH ?? {}
      return this.$moment(this.factoringTerms.periodStart).add(minValue, periodUnit[periodUnitType?.code]).toDate()
    },
    periodMaxDate () {
      const { maxValue, periodUnitType } = this.factoringProductAttributes?.AGREEMENT_LENGTH ?? {}
      return this.$moment(this.factoringTerms.periodStart).add(maxValue, periodUnitType?.code).toDate()
    },
    productInterestRate () {
      return this.factoringProductAttributes?.INTEREST_RATE ?? {}
    },
    productInvoiceLength () {
      return this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS ?? {}
    },
    feeCalculationTypeOptions () {
      return this.factoringTerms.fees.reduce((res, fee) => {
        return {
          ...res,
          [fee.feeType]: calculationTypes(fee, this.feeConfigs, this.classifierList('feeCalculationType'))
        }
      }, {})
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        this.$v.factoringData.customerBorrowerId.$model = selected?.id
        this.$v.factoringTerms.payoutBankAccount.$model = selected?.defaultExternalAccount?.accountExternalNumber
      }
    }
  },

  watch: {
    'factoringData.productId': 'fillProductData'
  },

  validations () {
    return {
      factoringData: {
        customerBorrowerId: {
          required: validators.required
        },
        productId: {
          required: validators.required
        }
      },
      factoringTerms: {
        periodStart: {
          required: validators.required
        },
        periodEnd: {
          required: validators.required
        },
        contractLimit: {
          required: validators.required
        },
        advanceRate: {
          required: validators.required
        },
        invoiceLengthMin: {
          required: validators.required
        },
        invoiceLengthMax: {
          required: validators.required
        },
        payoutBankAccount: {
          required: validators.required
        },
        invoiceLengthGroup: ['factoringTerms.invoiceLengthMin', 'factoringTerms.invoiceLengthMax']
      }
    }
  },

  async created () {
    await this.loadProducts({ group: 'FACTORING' })
    this.loadFeeConfig({ group: 'FACTORING' })
    if (this.productsBySector.length === 1) {
      const [product] = this.productsBySector
      this.factoringData.productId = product.value
    }

    if (this.customer) {
      this.selectedCustomer = this.customer
    }
  },

  methods: {
    ...mapActions('factoring', ['loadProduct', 'createFactoringContract']),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    async fillProductData () {
      await this.loadProduct({ id: this.factoringData.productId })
      this.$v.$reset()
      this.factoringTerms = {
        ...this.factoringTerms,
        advanceRate: this.factoringProductAttributes?.ADVANCE_RATE?.defaultValue,
        contractLimit: this.factoringProductAttributes?.AMOUNT_LIMIT?.defaultValue,
        invoiceLengthMin: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.minValue,
        invoiceLengthMax: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.maxValue,
        penaltyRate: this.factoringProductAttributes?.PENALTY_RATE?.defaultValue,
        fees: this.factoringProduct.fees
          .filter(({ feeType }) => feeType.code !== 'TERMINATION')
          .map(({ feeAmount, feeCalculationType, feeType }) => ({
            feeAmount,
            feeCalculationType: feeCalculationType.code,
            feeType: feeType.code
          })),
        invoiceLengthInterestRates: [{
          invoiceLength: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.defaultValue,
          periodInterestRate: undefined,
          yearlyInterestRate: this.factoringProductAttributes?.INTEREST_RATE?.defaultValue
        }]
      }
    },
    async createContract () {
      if (this.validate()) {
        await this.createFactoringContract({
          factoringData: {
            ...this.factoringData,
            term: this.factoringTerms,
            ...this.thirdParties.length ? { thirdParties: this.thirdParties.map(({ customerName, idCode, segmentId, sectorId, ...thirdParty }) => thirdParty) } : {}
          }
        })

        this.$router.push({ name: 'FactoringContract', params: { id: this.factoringContract.id } })
      }
    },
    updateThirdParty (index = -1, {
      thirdParty,
      done
    } = {}) {
      const newThirdPartiesList = [...this.thirdParties]

      if (typeof thirdParty === 'undefined') {
        newThirdPartiesList.splice(index, ~index && 1)
      } else {
        newThirdPartiesList.splice(index, ~index && 1, thirdParty)
      }

      this.thirdParties = newThirdPartiesList
      done && done()
    },
    onCancel () {
      this.$router.push({ name: 'FactoringList' })
    },
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.factoringTerms.invoiceLengthInterestRates.splice(index, 1)
      } else {
        this.factoringTerms.invoiceLengthInterestRates.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.factoringTerms.invoiceLengthInterestRates.push({
        invoiceLength: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.factoringProductAttributes?.INTEREST_RATE?.defaultValue
      })
    }
  }
}
</script>


<i18n>
en:
  customer:          "Customer"
  customerPh:        "Select customer"
  product:           "Product"
  productPh:         "Select product"
  factoringData:     "Factoring data"
  startDate:         "Start date"
  endDate:           "End date"
  contractLimit:     "Contract limit"
  advanceRate:       "Advance rate"
  invoiceLength:     "Invoice length (days)"
  interestRate:      "Interest rate"
  payoutBankAccount: "Payout bank account"
  penaltyRate:       "Penalty rate"
  rightOfRecourse:   "Right of recourse"
  specialTerms:      "Special terms"
et:
  customer:          "Customer"
  customerPh:        "Select customer"
  product:           "Product"
  productPh:         "Select product"
  factoringData:     "Factoring data"
  startDate:         "Start date"
  endDate:           "End date"
  contractLimit:     "Contract limit"
  advanceRate:       "Advance rate"
  invoiceLength:     "Invoice length (days)"
  interestRate:      "Interest rate"
  payoutBankAccount: "Payout bank account"
  penaltyRate:       "Penalty rate"
  rightOfRecourse:   "Right of recourse"
  specialTerms:      "Special terms"
ru:
  customer:          "Customer"
  customerPh:        "Select customer"
  product:           "Product"
  productPh:         "Select product"
  factoringData:     "Factoring data"
  startDate:         "Start date"
  endDate:           "End date"
  contractLimit:     "Contract limit"
  advanceRate:       "Advance rate"
  invoiceLength:     "Invoice length (days)"
  interestRate:      "Interest rate"
  payoutBankAccount: "Payout bank account"
  penaltyRate:       "Penalty rate"
  rightOfRecourse:   "Right of recourse"
  specialTerms:      "Special terms"
</i18n>
