<template lang="pug">
.factoring-financials.animated.fadeIn
  v-loading(loader = 'factoring:financial:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    template(v-if = 'factoringFinancial')
      .card.mb-0.border-bottom-0
        .card-header {{ $t('financials') }}
        .card-body
          .table-responsive
            table.table.table-hover
              thead: tr
                th.money.align-top
                  small.text-muted {{ $t('balance') }}
                  br
                  a.h4.text-primary(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PRINCIPAL_BALANCE", amount: factoringFinancial.balance, correction: true })'
                  ) {{ factoringFinancial.balance | money }}
                th.money.align-top
                  small.text-muted {{ $t('principalDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_PRINCIPAL_RECEIVABLE", "LEDGER_PRINCIPAL_INCOME"], amount: factoringFinancial.principalDebt, provision: true })'
                    :class      = 'debtClass(factoringFinancial.principalDebt)'
                  ) {{ factoringFinancial.principalDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('interestDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_INTEREST_RECEIVABLE", "LEDGER_INTEREST_INCOME"], amount: factoringFinancial.interestDebt, provision: true })'
                    :class      = 'debtClass(factoringFinancial.interestDebt)'
                  ) {{ factoringFinancial.interestDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('penaltyDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_PENALTY_RECEIVABLE", "LEDGER_PENALTY_INCOME"], amount: factoringFinancial.penaltyDebt, provision: true, thirdParties: optionsFromThirdParties })'
                    :class      = 'debtClass(factoringFinancial.penaltyDebt)'
                  ) {{ factoringFinancial.penaltyDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('feeDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_FEE_RECEIVABLE", "LEDGER_FEE_INCOME"], amount: factoringFinancial.feeDebt, provision: true, isDebt: true })'
                    :class      = 'debtClass(factoringFinancial.feeDebt)'
                  ) {{ factoringFinancial.feeDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('totalDebt') }}
                  br
                  a.h4(:class = 'debtClass(factoringFinancial.debts)') {{ factoringFinancial.debts | money }}
                th.money.align-top
                  small.text-muted {{ $t('interest') }}
                  br
                  a.h4.text-primary(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_ACCRUED", correction: true, amount: factoringFinancial.interestAccruedBalance })'
                  ) {{ factoringFinancial.interestAccruedBalance | money }}
                th.money.align-top
                  small.text-muted {{ $t('outstanding') }}
                  br
                  a.h4.text-primary {{ outstanding | money }}
              tbody
                tr
                  td.money {{ $t('receivable') }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PRINCIPAL_RECEIVABLE", correction: true})'
                    ) {{ factoringFinancial.principalReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_RECEIVABLE", correction: true })'
                    ) {{ factoringFinancial.interestReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PENALTY_RECEIVABLE", correction: true, thirdParties: optionsFromThirdParties })'
                    ) {{ factoringFinancial.penaltyReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_FEE_RECEIVABLE", correction: true, isDebt: true })'
                    ) {{ factoringFinancial.feeReceivableBalance | money }}
                  td.money {{ totalReceivableDebt | money }}
                  td(colspan=2)
                tr
                  td.money {{ $t('income') }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PRINCIPAL_INCOME", correction: true })'
                    ) {{ factoringFinancial.principalIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_INCOME", correction: true })'
                    ) {{ factoringFinancial.interestIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PENALTY_INCOME", correction: true, thirdParties: optionsFromThirdParties })'
                    ) {{ factoringFinancial.penaltyIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_FEE_INCOME", correction: true, isDebt: true })'
                    ) {{ factoringFinancial.feeIncomeBalance | money }}
                  td.money {{ totalIncomeDebt | money }}
                  td(colspan=2)
      factoring-financials-list-modal(
        v-model             = 'showTransactionsModal'
        v-bind              = 'modalOptions'
        :transaction-state  = 'factoringTransactions'
        :transactions-pages = 'factoringTransactionPages'
        :is-debt            = 'modalOptions.isDebt'
        loader              = 'factoring:contract:transactions:fetch'
        @search             = 'loadFactoringTransactions'
      )
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FactoringFinancialsListModal from '@/views/factoring/FactoringFinancialsListModal'

export default {
  name: 'factoring-financials',

  components: { FactoringFinancialsListModal },

  i18nOptions: {},

  data () {
    return {
      modalOptions: {
        accountType: '',
        accountTotalDebt: '',
        vat: false,
        correctionEnabled: false,
        provisionEnabled: false,
        thirdParties: null,
        isDebt: false
      },
      showTransactionsModal: false
    }
  },

  computed: {
    ...mapState('factoring', [
      'factoringContract',
      'factoringFinancial',
      'factoringProduct',
      'factoringTransactions',
      'factoringTransactionPages'
    ]),
    ...mapGetters('factoring', [
      'optionsFromThirdParties'
    ]),
    outstanding () {
      return this.factoringFinancial.balance + this.factoringFinancial.interestAccruedBalance + this.factoringFinancial.debts
    },
    totalReceivableDebt () {
      const {
        principalReceivableBalance = 0,
        interestReceivableBalance = 0,
        penaltyReceivableBalance = 0,
        feeReceivableBalance = 0,
        vatReceivableBalance = 0
      } = this.factoringFinancial

      return principalReceivableBalance +
        interestReceivableBalance +
        penaltyReceivableBalance +
        feeReceivableBalance +
        vatReceivableBalance
    },
    totalIncomeDebt () {
      const {
        principalIncomeBalance = 0,
        interestIncomeBalance = 0,
        penaltyIncomeBalance = 0,
        feeIncomeBalance = 0,
        vatPaidBalance = 0
      } = this.factoringFinancial

      return principalIncomeBalance +
        interestIncomeBalance +
        penaltyIncomeBalance +
        feeIncomeBalance +
        vatPaidBalance
    }
  },

  created () {
    this.loadFactoringFinancial({ contractId: this.$route.params.id })
    this.loadFactoringThirdParties({ contractId: this.$route.params.id })
  },

  methods: {
    ...mapActions('factoring', ['loadFactoringFinancial', 'loadFactoringContractTransactions', 'loadFactoringThirdParties']),
    debtClass (value) {
      return (value) ? 'text-danger' : 'text-success'
    },
    openTransactionsModal ({ accountType, amount, correction, provision, thirdParties, isDebt }) {
      this.modalOptions = {
        totalDebt: amount,
        accountType,
        correction,
        provision,
        thirdParties,
        isDebt
      }

      this.showTransactionsModal = true
    },
    loadFactoringTransactions ({ filters }) {
      this.loadFactoringContractTransactions({
        contractId: this.$route.params.id,
        filters: { accountTypes: this.modalOptions.accountType, ...filters }
      })
    }
  }
}
</script>


<i18n>
en:
  financials:    "Financial overview"
  transactions:  "Contract transactions"
  balance:       "Contract balance"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  penaltyDebt:   "Penalty debt"
  feeDebt:       "Fee debt"
  vatDebt:       "VAT debt"
  totalDebt:     "Total debt"
  interest:      "Interest accrued"
  outstanding:   "Total outstanding"
  receivable:    "Scheduled receivables"
  income:        "Client payments"
  gracePeriod:   "Grace period"
et:
  financials:    "Financial overview"
  transactions:  "Loan transactions"
  balance:       "Loan balance"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  penaltyDebt:   "Penalty debt"
  feeDebt:       "Fee debt"
  vatDebt:       "VAT debt"
  totalDebt:     "Total debt"
  interest:      "Interest accrued"
  outstanding:   "Total outstanding"
  receivable:    "Scheduled receivables"
  income:        "Client payments"
  gracePeriod:   "Grace period"
ru:
  financials:    "Financial overview"
  transactions:  "Loan transactions"
  balance:       "Loan balance"
  principalDebt: "Principal debt"
  interestDebt:  "Interest debt"
  penaltyDebt:   "Penalty debt"
  feeDebt:       "Fee debt"
  vatDebt:       "VAT debt"
  totalDebt:     "Total debt"
  interest:      "Interest accrued"
  outstanding:   "Total outstanding"
  receivable:    "Scheduled receivables"
  income:        "Client payments"
  gracePeriod:   "Grace period"
</i18n>
