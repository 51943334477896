<template lang="pug">
tr.factoring-contract-overview-third-party-list-item
  td
    v-loading(:loader = '`customers:${thirdParty.customerId}:fetch`')
      .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
      router-link(:to = '{ name: "Customer", params: { id: thirdParty.customerId } }') {{ customerName(customer) }}
  td
    router-link(
      v-if = 'mainContact.id'
      :to  = '{ name: "Customer", params: { id: mainContact.id } }'
    ) {{ customerName(mainContact) }}
  td.money {{ thirdParty.limit | money }}
  td.money {{ thirdParty.availableLimit | money }}
  td
    button.btn.btn-link.py-0(:id = 'invoiceTermId')
      i.fa-percent.fas.text-primary
  td {{ $t('common:formatDate', { value: thirdParty.endDate }) }}
  td
    button.btn.btn-link.py-0(
      v-if = 'thirdParty.notes'
      :id  = '`${invoiceTermId}_notes`'
    )
      i.fas.text-primary.fa-comment-alt
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
  b-popover(
    :target   = 'invoiceTermId'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row.text-nowrap(v-for = 'interestRate in thirdParty.invoiceLengthInterestRates')
      .col {{ interestRate.invoiceLength }} {{ $t('days') }}
      .col {{ interestRate.periodInterestRate | percent }}
      .col {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
  b-popover(
    :target   = '`${invoiceTermId}_notes`'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row
      .col.third-party-notes {{ thirdParty.notes }}
</template>


<script>
import api from '@/api'
import { mapGetters } from 'vuex'

const COLOR_MAP = {
  ACTIVE: 'primary',
  INACTIVE: 'secondary'
}

export default {
  name: 'factoring-contract-overview-third-party-list-item',

  props: {
    thirdParty: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    customer: null
  }),

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierById']),
    mainContact () {
      return this.customer?.referredRelations?.find(({ mainContact, active }) => mainContact && active)?.customer ?? this.customer ?? {}
    },
    status () {
      return this.classifierById('thirdPartyStatus', this.thirdParty.status?.code)
    },
    statusClass () {
      return COLOR_MAP[this.thirdParty.status?.code]
    },
    invoiceTermId () {
      return `${this.thirdParty.id}_invoiceTerms`
    }
  },

  async created () {
    const { data: customer } = await api.getCustomer({ id: this.thirdParty.customerId })
    this.customer = customer
  }
}
</script>
