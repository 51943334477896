<template lang="pug">
fi-modal.factoring-contract-third-party-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    template(v-if = '!thirdParty')
      fi-form-field(
        :label     = '$t("existingCustomer")'
        label-cols = 3
      )
        fi-customer-select(
          v-model      = 'selectedCustomerModel'
          :placeholder = '$t("selectCustomer")'
        )
      .form-group
        .col-sm-9.offset-sm-3
          .my-2 - {{ $t('or') }} -
          customer-new-brief(
            v-if    = 'createNewCustomer'
            @cancel = 'createNewCustomer = false'
            @submit = 'onCreateCustomer'
          )
          button.btn.btn-link.px-0(
            v-else
            @click.prevent = 'createNewCustomer = true'
          ) {{ $t('createCustomer') }}
    fi-form-field(
      :label     = '$t("status")'
      label-cols = 3
    )
      fi-select(
        v-model  = 'thirdPartyData.status'
        :options = 'optionsFromClassifier("thirdPartyStatus", true)'
        sm
      )
    fi-form-field(
      :label     = '$t("advanceRate")'
      label-cols = 3
    )
      percent-input.form-control-sm(v-model = 'thirdPartyData.advanceRate')
    fi-form-field(
      :label     = '$t("contractLimit")'
      label-cols = 3
    )
      fi-money-input.form-control-sm(v-model = 'thirdPartyData.limit')
    fi-form-field(
      :label     = '$t("invoiceLength")'
      label-cols = 3
    )
      .row
        .input-group.col.input-group-sm
          .input-group-prepend
            span.input-group-text {{ $t('common:min') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model.number = 'thirdPartyData.invoiceLengthMin'
          )
        .input-group.col.input-group-sm
          .input-group-prepend
            span.input-group-text {{ $t('common:max') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model.number = 'thirdPartyData.invoiceLengthMax'
          )
    fi-form-field(
      :label     = '$t("startDate")'
      label-cols = 3
    )
      fi-datepicker(v-model = 'thirdPartyData.startDate')
    fi-form-field(
      :label     = '$t("endDate")'
      label-cols = 3
    )
      fi-datepicker(v-model = 'thirdPartyData.endDate')
    fi-form-field(
      :label     = '$t("interestRate")'
      label-cols = 3
    )
      application-factoring-interest-rate-item(
        v-for                 = '(interestRate, index) in thirdPartyData.invoiceLengthInterestRates'
        :key                  = 'index'
        :interest-rate        = 'interestRate'
        :day-count-convention = 'productInterestRate.dayCountConvention'
        @input                = 'updateInterestRate($event, index)'
      )
        .col-2.px-1.position-flex-space-around
          button.btn.btn-danger.btn-sm(
            v-if           = 'index'
            @click.prevent = 'updateInterestRate(undefined, index)'
          ): i.far.fa-trash-alt.fa-lg
          button.btn.btn-primary.btn-sm(
            v-else
            @click.prevent = 'addInterestRate'
          ): i.far.fa-plus-square.fa-lg
          div.checkbox(id = `${index}`)
            input.checkbox-input(
              v-model = 'interestRate.availableOnPortal'
              type    = 'checkbox'
              :id      =  '"checkbox" + index'
            )
            label.checkbox-label( :for = '"checkbox" + index' )
              i.fa.fa-check-square
    fi-form-field(
      :label     = '$t("notes")'
      label-cols = 3
      :state     = '!$v.thirdPartyData.notes.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.thirdPartyData.notes.max') {{ $t('maxLengthError') }}
      textarea.form-control.form-control-sm(
        v-model.trim = 'thirdPartyData.notes'
        :class       = '{ "is-invalid": $v.thirdPartyData.notes.$error }'
        rows         = 3
      )
  template(#modal-footer)
    button.btn.btn-warning(@click = 'applyToThirdParty') {{ $t('applyFromTerms') }}
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import cloneDeep from 'lodash/cloneDeep'
import FiDatepicker from '@/components/FiDatepicker'
import FiSelect from '@/components/FiSelect'
import { mapGetters } from 'vuex'
import pick from 'lodash/pick'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import { validation, validators } from '@/validation'

export default {
  name: 'factoring-contract-third-party-modal',

  components: {
    CustomerNewBrief,
    FiCustomerSelect,
    FiSelect,
    FiDatepicker,
    ApplicationFactoringInterestRateItem,
    FiMoneyInput,
    PercentInput,
    FiFormField,
    FiModal
  },

  mixins: [validation],

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    thirdParty: {
      type: Object,
      default: null
    },
    productAttributes: {
      type: Object,
      required: true
    },
    sourceData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    createNewCustomer: false,
    selectedCustomer: null,
    thirdPartyData: {
      customerId: '',
      advanceRate: null,
      invoiceLengthInterestRates: [],
      limit: null,
      startDate: '',
      endDate: '',
      status: null,
      invoiceLengthMax: '',
      invoiceLengthMin: '',
      notes: ''
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        this.thirdPartyData = {
          ...this.thirdPartyData,
          ...pick(selected, Object.keys(this.thirdPartyData)),
          customerId: selected?.id
        }
      }
    },
    saving () {
      return this.loader
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    productInterestRate () {
      return this.productAttributes?.INTEREST_RATE
    },
    productInvoiceLength () {
      return this.productAttributes?.INVOICE_LENGTH_IN_DAYS
    }
  },

  validations: {
    thirdPartyData: {
      notes: {
        max: validators.maxLength(100)
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.thirdParty) {
        this.thirdPartyData = { ...cloneDeep(this.thirdParty), status: this.thirdParty.status?.code }
      }

      if (!this.thirdPartyData.invoiceLengthInterestRates?.length) {
        this.thirdPartyData.invoiceLengthInterestRates = [{
          invoiceLength: this.productInvoiceLength?.defaultValue,
          periodInterestRate: undefined,
          yearlyInterestRate: this.productInterestRate?.defaultValue
        }]
      }
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        this.$emit('submit', {
          thirdPartyId: this.thirdParty?.id,
          thirdPartyData: this.thirdPartyData,
          done: () => this.$refs.modal.hide()
        })
      }
    },
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.thirdPartyData.invoiceLengthInterestRates.splice(index, 1)
      } else {
        this.thirdPartyData.invoiceLengthInterestRates.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.thirdPartyData.invoiceLengthInterestRates.push({
        invoiceLength: this.productInvoiceLength?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.productInterestRate?.defaultValue
      })
    },
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    },
    applyToThirdParty () {
      const {
        contractLimit: limit,
        advanceRate,
        invoiceLengthMin,
        invoiceLengthMax,
        invoiceLengthInterestRates,
        periodStart: startDate,
        periodEnd: endDate
      } = this.sourceData
      this.thirdPartyData = {
        ...this.thirdPartyData,
        advanceRate,
        limit,
        invoiceLengthMax,
        invoiceLengthMin,
        startDate,
        endDate,
        ...invoiceLengthInterestRates?.length ? { invoiceLengthInterestRates: cloneDeep(invoiceLengthInterestRates) } : {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .checkbox {
    position: relative;
    margin-top: 6px;
  }
  .checkbox-input {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
  }
  .checkbox-label:before {
    content: "";
    display: block;
    height: 13px;
    width: 12px;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1;
  }
  .checkbox-label>i {
    position: absolute;
    top: 3px;
    display: none;

  }
  .checkbox-input:checked + .checkbox-label>i {
    display: block;
  }
  .checkbox-label {
    margin: 0;
  }

</style>


<i18n>
en:
  titleEdit:        "Edit third party terms"
  titleAdd:         "Add third party terms"
  existingCustomer: "Existing customer"
  selectCustomer:   "Select customer"
  createCustomer:   "Create customer"
  status:           "Status"
  contractLimit:    "Limit"
  advanceRate:      "Advance rate"
  interestRate:     "Interest rate"
  startDate:        "Start date"
  endDate:          "End date"
  days:             "days"
  yearly:           "yearly"
  applyFromTerms:   "Apply from agreement terms"
  maxLengthError:   "Notes should be no more than 100 symbols"
et:
  titleEdit:        "Edit third party terms"
  titleAdd:         "Add third party terms"
  existingCustomer: "Existing customer"
  selectCustomer:   "Select customer"
  createCustomer:   "Create customer"
  status:           "Status"
  contractLimit:    "Limit"
  advanceRate:      "Advance rate"
  interestRate:     "Interest rate"
  startDate:        "Start date"
  endDate:          "End date"
  days:             "days"
  yearly:           "yearly"
  applyFromTerms:   "Apply from agreement terms"
  maxLengthError:   "Notes should be no more than 100 symbols"
ru:
  titleEdit:        "Edit third party terms"
  titleAdd:         "Add third party terms"
  existingCustomer: "Existing customer"
  selectCustomer:   "Select customer"
  createCustomer:   "Create customer"
  status:           "Status"
  contractLimit:    "Limit"
  advanceRate:      "Advance rate"
  interestRate:     "Interest rate"
  startDate:        "Start date"
  endDate:          "End date"
  days:             "days"
  yearly:           "yearly"
  applyFromTerms:   "Apply from agreement terms"
  maxLengthError:   "Notes should be no more than 100 symbols"
</i18n>
