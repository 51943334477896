<template lang="pug">
tr.factoring-contract-overview-schedule-invoice-list-item(:class = '{ "bg-light": editing }')
  td {{ invoice.invoiceNumber }}
  td {{ $t('common:formatDate', { value: invoice.creationDate }) }}
  td
    .animated.fadeIn.form-row(v-if = 'editing')
      fi-datepicker(
        v-model   = 'deadline'
        :min-date = 'systemData.nextDayChange'
      )
    .animated.fadeIn(v-else) {{ $t('common:formatDate', { value: invoice.deadlineDate }) }}
  td.money {{ invoice.balance | money }}
  td.money {{ invoice.principal | money }}
  td.money
    .animated.fadeIn.form-row(v-if = 'editing')
      .col-4.ml-auto
        fi-money-input.text-right(v-model="interest")
    .animated.fadeIn(v-else) {{ invoice.interest | money }}
  td.money.has-popover(:id = 'invoiceUid') {{ invoice.fee | money }}
  td.money {{ invoice.penaltyAmount | money }}
  td.money {{ sum | money }}
  td.money {{ invoice.amountPaid | money }}
  td {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(scheduleStatus)}`') {{ scheduleStatus.human }}
  td
    template(v-if = 'invoice.invoiceNumber && invoice.status.code === "PENDING"')
      .animated.fadeIn(v-if = 'editing')
        .btn-group.btn-group.btn-group-sm
          button.btn.btn-success.btn-sm(v-fi-confirm = 'confirmParams')
            i.fas.fa-check.fa-fw
      button.btn-sm.btn.btn-outline-primary.animated.fadeIn(
        v-else
        :disabled = '!editable'
        @click = 'toggleEditing(true)'
      )
        i.far.fa-edit.fa-lg.fa-fw
  b-popover(
    :target   = 'invoiceUid'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row.text-nowrap.text-alig(v-for = '(feeDetails) in invoice.feeDetails')
      .row.text-nowrap(v-for = '(value, key) in feeDetails')
        .col-6 {{ key }}
        .col-6 {{ value | money }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BigNumber from 'bignumber.js'
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'

export default {
  name: 'factoring-contract-overview-schedule-invoice-list-item',

  components: { FiMoneyInput, FiFormField, FiDatepicker },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean
    }
  },

  data: (vm) => ({
    deadline: vm.invoice.deadlineDate,
    interest: vm.invoice.interest,
    editing: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    ...mapState('loans', ['systemData']),
    scheduleStatus () {
      return this.classifierById(this.invoice.status.classifier, this.invoice.status.id)
    },
    invoiceUid () {
      return `invoice_${this._uid}`
    },
    sum () {
      const { interest, fee, principal, penaltyAmount } = this.invoice
      return new BigNumber(interest).plus(fee).plus(principal).plus(penaltyAmount).toNumber()
    },
    confirmParams () {
      return {
        message: this.$t('saveConfirm'),
        ok: this.saveInvoice,
        cancel: () => this.toggleEditing(false),
        loader: `factoring:invoices:schedule:${this.invoice.invoiceNumber}:deadline:save`
      }
    }
  },

  methods: {
    toggleEditing (state = !this.editing) {
      this.editing = state
      this.deadline = this.invoice.deadlineDate
      this.interest = this.invoice.interest
      this.$emit('update:editable', !state)
    },
    saveInvoice ({ done }) {
      const { creationDate, invoiceNumber, deadlineDate } = this.invoice
      this.$emit('save', {
        invoiceDate: creationDate,
        invoiceNumber,
        interestAmount: this.interest,
        newPaymentDate: this.deadline,
        oldPaymentDate: deadlineDate,
        done
      })
    }
  }
}
</script>


<i18n>
en:
  saveConfirm: "Are you sure to save?"
et:
  saveConfirm: "Are you sure to save?"
ru:
  saveConfirm: "Are you sure to save?"
</i18n>

<style lang="scss" scoped>
.has-popover {
  cursor: pointer;
}
.text-alig {
  width: fit-content;
  margin-left: 0;
}
</style>
