<template lang="pug">
.factoring-overview
  v-loading(loader = 'factoring:contract:customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    customer-note-list(:comments="factoringComments")
    template(v-if = 'factoringCustomer && factoringContractOverview')
      .row
        .col-sm-4.col-lg-3
          loan-overview-widget(
            :title = '$t("status")'
            :value = 'status.human'
            :color = 'loanStatusColor(status.name)'
          )
            loan-status-dropdown.fa-pull-right(
              :statuses  = 'availableStatuses'
              :header    = '$t("statusSelect")'
              @submit    = 'changeStatus'
            )
            fi-confirm-modal(
              v-model      = 'showConfirmModal'
              :ok-callback = 'setStatus'
              loader      = 'factoring:contract:status:save'
            )
            loan-sign-modal(
              v-if      = 'showSignModal'
              v-model   = 'showSignModal'
              :loan     = 'factoringContract'
              :callback = 'setStatus'
              loader    = 'factoring:contract:status:save'
            )
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("totalBalance")'
          :value = 'factoringCustomer.customerOverallBalance | money'
          color  = 'primary'
        )
          router-link.text-white(
            :to = '{ name: "Debts", query: { criteria: factoringCustomer.idCode } }'
          ).fa-pull-right.text-right
            h4.m-0 {{ factoringContractOverview.totalDebt | money }}
            p.m-0 {{ $t('totalDebt') }}

        .col-sm-6.col-lg: loan-overview-widget(
          :color = 'factoringContractOverview.totalPrepayment ? "success" : "primary"'
        )
          router-link.text-white(
            :to = '{ name: "CustomerPaymentInfo", params: { id: factoringCustomer.id } }'
          )
            h4.m-0 {{ factoringContractOverview.totalPrepayment | money }}
            p.m-0 {{ $t("prepayment") }}
      .row(v-if = 'isActive')
        .col-sm-4.col-lg-3: loan-overview-widget(
          :title = '$t("availableLimit")'
          :value = 'factoringContractOverview.amountLimit | money'
          color  = 'primary'
        )
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("contractBalance")'
          :value = 'factoringFinancial && factoringFinancial.balance | money'
          color  = 'primary'
        )
          .fa-pull-right.text-right
            h4.m-0 {{ factoringFinancial && factoringFinancial.debts | money }}
            p.m-0 {{ $t('contractDebt') }}
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("interestAccrued")'
          :value = 'factoringFinancial && factoringFinancial.interestAccruedBalance || 0 | money'
          color  = 'primary'
        )
    .row
      .col-3
        factoring-contract-details(v-if="factoringCustomer")
        loan-customer(
          :customer     = 'factoringManager'
          :customerType = '$t("manager")'
        )
          template(v-if = 'editable')
            button.btn.text-danger(
              v-if   = 'factoringManager'
              @click = 'setManager()'
            )
              i.fa.fa-cog.fa-pulse(v-if = '$vueLoading.isLoading(`factoring:contract:save`)')
              i.cil-user-unfollow(v-else)
            application-assign-dropdown.colored(
              :application  = 'factoringContract'
              :loader       = '`factoring:contract:save`'
              no-caret
              @assign       = 'setManager'
            )

      .col-9
        factoring-contract-invoices-overview(
          :schedule-invoices = 'scheduleInvoiceList'
          :contract-invoices = 'importedInvoiceList'
        )
        factoring-contract-overview-third-party-list(:third-parties = 'factoringThirdParties')
</template>


<script>
import CustomerNoteList from '@/views/customers/CustomerNoteList'
import LoanOverviewWidget from '@/views/loans/LoanOverview/LoanOverviewWidget'
import LoanStatusDropdown from '@/views/loans/LoanStatusDropdown'
import FiConfirmModal from '@/components/FiConfirmModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import FactoringContractDetails from '@/views/factoring/FactoringContractDetails'
import FactoringContractInvoicesOverview from '@/views/factoring/FactoringContractInvoicesOverview'
import { loanStatuses } from '@/const'
import FactoringContractOverviewThirdPartyList from '@/views/factoring/FactoringContractOverviewThirdPartyList'
import LoanSignModal from '@/views/loans/LoanSignModal'
import LoanCustomer from '@/views/loans/LoanOverview/LoanOverviewCustomer'
import ApplicationAssignDropdown from '@/views/applications/ApplicationAssignDropdown'

export default {
  name: 'factoring-overview',

  components: {
    LoanSignModal,
    FactoringContractOverviewThirdPartyList,
    FactoringContractInvoicesOverview,
    FactoringContractDetails,
    FiConfirmModal,
    LoanStatusDropdown,
    LoanOverviewWidget,
    CustomerNoteList,
    LoanCustomer,
    ApplicationAssignDropdown
  },

  i18nOptions: {},

  data: () => ({
    newStatusCode: '',
    showSignModal: false,
    showActivateModal: false,
    showConfirmModal: false,
    changeStatusErrorAction: null,
    confirmMessage: '',
    showInitiatePaymentModal: false
  }),

  computed: {
    ...mapState('factoring',
      [
        'factoringContract',
        'factoringContractOverview',
        'factoringProduct',
        'factoringCustomer',
        'importedInvoiceList',
        'scheduleInvoiceList',
        'factoringThirdParties',
        'factoringManager',
        'factoringFinancial',
        'factoringComments'
      ]
    ),
    editable () {
      return [loanStatuses.matured].every(status => this.factoringContract.status.code !== status)
    },
    ...mapGetters('classifiers', ['classifierByName', 'optionsFromClassifier', 'managerById', 'optionsFromManagers']),
    ...mapGetters('loans', ['loanStatusColor']),
    status () {
      const { classifier, code } = this.factoringContract?.status ?? {}
      return this.classifierByName(classifier, code) ?? {}
    },
    isActive () {
      const { inserted, signed, pending, deleted, matured } = loanStatuses
      return ![inserted, signed, pending, deleted, matured].includes(this.factoringContract.status.code)
    },
    availableStatuses () {
      return this.factoringContract.availableStatuses
    }
  },

  created () {
    this.loadComments({ filters: { active: true, hasAction: false } })
    this.loadFactoringThirdParties({ contractId: this.factoringContract?.id })
    this.loadFactoringContractOverview({ id: this.$route.params?.id })
    this.loadFactoringManager({ id: this.factoringContract?.managerId })
  },

  methods: {
    ...mapActions('factoring', ['saveFactoringStatus', 'loadFactoringThirdParties', 'loadFactoringContractOverview', 'saveFactoringContract', 'loadFactoringManager', 'loadComments']),
    changeStatus ({ code }) {
      const { pending, signed, active } = loanStatuses
      switch (code) {
        case pending:
          this.confirmMessage = ''
          this.showConfirmModal = true
          break
        case signed:
          this.showSignModal = true
          break
        case active:
          if (this.status.id === pending) {
            this.showActivateModal = true
          } else {
            this.confirmMessage = ''
            this.showConfirmModal = true
          }
          break
        default:
          this.confirmMessage = ''
          this.showConfirmModal = true
      }
      this.newStatusCode = code
    },
    async setStatus ({ done, contractData }) {
      await this.saveFactoringStatus({
        contractId: this.factoringContract.id,
        statusId: this.newStatusCode,
        statusData: contractData
      })
      done()
    },
    async setManager ({ id = this.factoringContract.id, customerId = '' } = {}) {
      const data = { managerId: customerId }
      await this.saveFactoringContract({ id, factoringData: data })
      this.loadFactoringManager({ id: customerId })
    }
  }
}
</script>


<i18n>
en:
  status:          "Contract status"
  statusSelect:    "Select new status"
  totalBalance:    "Customer total balance"
  totalDebt:       "Customer total debt"
  contractBalance: "Contract balance"
  contractDebt:    "Contract debt"
  prepayment:      "Total prepayment"
  availableLimit:  "Available limit"
  interestAccrued: "Interest accrued"
  manager:         "Manager"
et:
  status:          "Contract status"
  statusSelect:    "Select new status"
  totalBalance:    "Customer total balance"
  totalDebt:       "Customer total debt"
  contractBalance: "Contract balance"
  contractDebt:    "Contract debt"
  prepayment:      "Total prepayment"
  availableLimit:  "Available limit"
  interestAccrued: "Interest accrued"
  manager:         "Manager"
ru:
  status:          "Contract status"
  statusSelect:    "Select new status"
  totalBalance:    "Customer total balance"
  totalDebt:       "Customer total debt"
  contractBalance: "Contract balance"
  contractDebt:    "Contract debt"
  prepayment:      "Total prepayment"
  availableLimit:  "Available limit"
  interestAccrued: "Interest accrued"
  manager:         "Manager"
</i18n>
