<template lang="pug">
.factoring-contract-details.animated.fadeIn.card
  .card-header {{ $t('title') }}
  .card-body
    fi-data-field(
      v-if   = 'factoringContract.status.code !== "INSERTED"'
      :label = '$t("signDate")'
    ) {{ $t('common:formatDate', { value: factoringContract.signingDate }) }}
    fi-data-field(:label = '$t("reference")')
      router-link(
        v-if = 'factoringAccount'
        :to  = '{ name: "CustomerPaymentInfo", params: { id: factoringCustomer.id, reference: factoringAccount.referenceNumber } }'
      ) {{ factoringAccount.referenceNumber }}
    fi-data-field(:label = '$t("limit")') {{ factoringContractOverview.amountLimit | money }}
    fi-data-field(:label = '$t("advanceRate")') {{ factoringContract.term.advanceRate | percent }}
    fi-data-field(:label = '$t("startDate")') {{ $t('common:formatDate', { value: factoringContract.term.periodStart }) }}
    fi-data-field(:label = '$t("endDate")') {{ $t('common:formatDate', { value: factoringContract.term.periodEnd }) }}
    fi-data-field(
      v-for  = '(fee, index) in fees'
      :key   = 'index'
      :label = 'classifierByCode(fee.feeType.classifier, fee.feeType.code).human'
    )
      template(v-if = 'feePercentage(fee.feeCalculationType.code)') {{ fee.feeAmount | percent }}
      template(v-else) {{ fee.feeAmount | money }}
    fi-data-field(:label = '$t("penaltyRate")') {{ factoringContract.term.penaltyRate | percent }}
    fi-data-field(:label = '$t("mainContact")')
      router-link(
        v-if = 'mainContact.id'
        :to  = '{ name: "Customer", params: { id: mainContact.id } }'
      ) {{ fullName }}
</template>

<script>
import FiDataField from '@/components/FiDataField'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isFeePercentage } from '@/mixins'
import { customerName } from '@/helpers'

export default {
  name: 'factoring-contract-details',

  components: { FiDataField },

  mixins: [isFeePercentage],

  computed: {
    ...mapState('factoring', ['factoringContract', 'factoringProduct', 'factoringCustomer', 'factoringAccount', 'factoringContractTerms', 'factoringFinancial', 'factoringContracts', 'factoringContractOverview']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByCode']),
    ...mapGetters('customers', ['customerName']),
    fees () {
      return this.factoringContract?.term?.fees
        .filter(({ feeType }) => feeType.code !== 'TERMINATION')
    },
    fullName () {
      const { idCode } = this.mainContact
      return `${customerName(this.mainContact)} (${idCode})`
    },
    mainContact () {
      return this.factoringCustomer?.referredRelations?.find(({ mainContact, active }) => mainContact && active)?.customer ?? this.factoringCustomer ?? {}
    }
  },

  created () {
    this.loadFactoringAccount({ accountId: this.factoringContract.prepaymentAccountId })
  },

  methods: {
    ...mapActions('factoring', ['loadFactoringAccount'])
  }
}
</script>


<i18n>
en:
  title:       "Contract details"
  signDate:    "Signing date"
  reference:   "Reference"
  limit:       "Contract limit"
  advanceRate: "Advance rate"
  startDate:   "Start date"
  endDate:     "End date"
  penaltyRate: "Penalty interest"
  mainContact: "Main contact"
et:
  title:       "Contract details"
  signDate:    "Signing date"
  reference:   "Reference"
  limit:       "Contract limit"
  advanceRate: "Advance rate"
  startDate:   "Start date"
  endDate:     "End date"
  penaltyRate: "Penalty interest"
  mainContact: "Main contact"
ru:
  title:       "Contract details"
  signDate:    "Signing date"
  reference:   "Reference"
  limit:       "Contract limit"
  advanceRate: "Advance rate"
  startDate:   "Start date"
  endDate:     "End date"
  penaltyRate: "Penalty interest"
  mainContact: "Main contact"
</i18n>
