<template lang = 'pug'>
fi-modal.factoring-termination-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("periodEnd")'
      :state = '!$v.terminationData.periodEnd.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.terminationData.periodEnd.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model = 'terminationData.periodEnd'
        :class  = '{ "is-invalid": $v.terminationData.periodEnd.$error }'
        :min-date = 'terminationMinDate'
        :max-date = 'periodEnd'
        sm
      )
    fi-form-field(
      :label     = '$t("terminationFee")'
      :state     = '!$v.terminationData.terminationFee.$error'
      :disabled  = '!terminationFee.feeOverride'
    )
      template(#error)
        .error-message(v-if = '!$v.terminationData.terminationFee.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.terminationData.terminationFee.gte') {{ $t('gte', { value: $v.terminationData.terminationFee.$params.gte.value }) }}
        .error-message(v-if = '!$v.terminationData.terminationFee.lte') {{ $t('lte', { value: $v.terminationData.terminationFee.$params.lte.value }) }}
      .form-row
        .col: fi-select(
          v-model.number = 'terminationData.terminationFeeCalculationTypeId'
          :options       = 'feeCalculationTypeOptions'
          :disabled       = '!terminationFee.feeOverride'
          required
          sm
        )
        .col
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageFee'
            v-model.number = 'terminationData.terminationFee'
            :class         = '{ "is-invalid": $v.terminationData.terminationFee.$error }'
            :disabled      = '!feeRequired'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model.number = 'terminationData.terminationFee'
            :class         = '{ "is-invalid": $v.terminationData.terminationFee.$error }'
            :disabled      = '!feeRequired'
          )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'loader'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { validation, validators } from '@/validation/index'
import { mapGetters, mapState } from 'vuex'
import { convertFee, convertFeeRange, feePercentage } from '@/helpers'

export default {
  name: 'factoring-termination-modal',

  components: {
    FiSelect,
    FiFormField,
    FiDatepicker,
    FiMoneyInput,
    PercentInput,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    loader: {
      type: Boolean,
      required: true
    },
    terminationFee: {
      type: Object,
      required: true
    },
    contractAmount: {
      type: [Number, String],
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    },
    periodStart: {
      type: String,
      required: true
    },
    periodEnd: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      terminationData: {
        periodEnd: '',
        terminationFee: null,
        terminationFeeCalculationTypeId: ''
      }
    }
  },

  computed: {
    ...mapState('loans', ['systemData']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName']),
    ...mapGetters('settings', ['settingByKey']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    feeCalculationTypeOptions () {
      const feeType = Object.keys(this.feeConfigs).includes(this.terminationFee.feeType.code) ? this.terminationFee.feeType.code : 'CUSTOM_FEE'
      const feeCalculationTypePossible = this.feeConfigs[feeType]
      return this.optionsFromClassifier(this.terminationFee?.feeCalculationType?.classifier, true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    percentageFee () {
      return feePercentage(this.feeCalcType.code)
    },
    convertedTerminationFeeRange () {
      return convertFeeRange(this.terminationFee, this.contractAmount)
    },
    feeRequired () {
      return this.terminationFee.feeMin !== this.terminationFee.feeMax
    },
    terminationMinDate () {
      return this.$moment.max(
        this.$moment(this.systemData.nextDayChange),
        this.$moment(this.periodStart)
      ).toDate()
    },
    feeCalcType () {
      const { feeCalculationType } = this.terminationFee
      return this.classifierByName(feeCalculationType.classifier, this.terminationData.terminationFeeCalculationTypeId) ?? {}
    }
  },

  watch: {
    'terminationData.terminationFeeCalculationTypeId' (val, oldVal) {
      if (!oldVal) return
      this.terminationData.terminationFee = convertFee({
        feeValue: this.terminationData.terminationFee,
        isPercentage: this.percentageFee,
        amountValue: this.contractAmount
      })
    }
  },

  validations () {
    return {
      terminationData: {
        periodEnd: {
          required: validators.requiredIf(function () {
            return this.feeRequired
          })
        },
        terminationFee: {
          required: validators.requiredIf(function () {
            return this.feeRequired
          })
        }
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.terminationData.terminationFee = this.terminationFee.feeAmount
      this.terminationData.terminationFeeCalculationTypeId = this.terminationFee.feeCalculationType.code
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        this.$emit('submit', {
          data: {
            ...this.terminationData,
            terminationFeeCalculationTypeId: this.feeCalcType.id
          },
          done: () => this.$refs.modal.hide()
        })
      }
    }
  }
}
</script>


<i18n>
en:
  title:          "Termination"
  periodEnd:      "Termination date"
  terminationFee: "Termination fee"
et:
  title:          "Termination"
  periodEnd:      "Termination date"
  terminationFee: "Termination fee"
ru:
  title:          "Termination"
  periodEnd:      "Termination date"
  terminationFee: "Termination fee"
</i18n>
