<template lang="pug">
.factoring-contract-third-party-list.card
  .card-header {{ $t('title') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'thirdParties'
      :empty-label = '$t("notFound")'
      loader       = 'factoring:contract:thirdParties:fetch'
    )
      template(#default = '{ items }')
        factoring-contract-overview-third-party-list-item(
          v-for        = 'thirdParty in items'
          :third-party = 'thirdParty'
          :key         = 'thirdParty.id'
        )
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringContractOverviewThirdPartyListItem from '@/views/factoring/FactoringContractOverviewThirdPartyListItem'

export default {
  name: 'factoring-contract-overview-third-party-list',

  components: { FactoringContractOverviewThirdPartyListItem, FiTable },

  props: {
    thirdParties: {
      type: Array,
      required: true
    }
  },

  computed: {
    fields () {
      return [
        {
          key: 'thirdParty',
          label: this.$t('thirdParty')
        },
        {
          key: 'mainContact',
          label: this.$t('mainContact')
        },
        {
          key: 'limit',
          label: this.$t('limit'),
          class: 'money'
        },
        {
          key: 'availableLimit',
          label: this.$t('availableLimit'),
          class: 'money'
        },
        {
          key: 'interestTerms',
          label: this.$t('interestTerms')
        },
        {
          key: 'endDate',
          label: this.$t('endDate')
        },
        {
          key: 'notes',
          label: this.$t('notes')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  availableLimit: "Available limit"
  advanceRate:    "Advance rate"
  interestTerms:  "Interest terms"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "Third-parties not found"
et:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  availableLimit: "Available limit"
  advanceRate:    "Advance rate"
  interestTerms:  "Interest terms"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "Third-parties not found"
ru:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  availableLimit: "Available limit"
  advanceRate:    "Advance rate"
  interestTerms:  "Interest terms"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "Third-parties not found"
</i18n>
