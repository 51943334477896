<template lang="pug">
.factoring-contract-third-party-list.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.py-0.animated.fadeIn(
      :title         = '$t("common:add")'
      @click.prevent = 'showAddThirdParty = true'
    ): i.fas.fa-user-plus.fa-lg
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'thirdParties'
      :empty-label = '$t("notFound")'
    )
      template(#default = '{ items }')
        factoring-contract-third-party-list-item(
          v-for               = '(thirdParty) in items'
          v-on                = 'itemListeners'
          :third-party        = 'thirdParty'
          :product-attributes = 'factoringProductAttributes'
          :key                = 'thirdParty.id'
          :source-data        = 'sourceData'
          editable
        )
  factoring-contract-third-party-modal(
    v-if                = 'showAddThirdParty'
    v-model             = 'showAddThirdParty'
    v-on                = 'createListener'
    :product-attributes = 'factoringProductAttributes'
    :loader             = '$vueLoading.isLoading("factoring:contract:thirdParty:save")'
    :source-data        = 'sourceData'
  )
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringContractThirdPartyListItem from '@/views/factoring/FactoringContractThirdPartyListItem'
import { mapGetters } from 'vuex'
import FactoringContractThirdPartyModal from '@/views/factoring/FactoringContractThirdPartyModal'
import pickBy from 'lodash/pickBy'

export default {
  name: 'factoring-contract-third-party-list',

  components: { FactoringContractThirdPartyModal, FactoringContractThirdPartyListItem, FiTable },

  props: {
    full: {
      type: Boolean,
      default: false
    },
    thirdParties: {
      type: Array,
      required: true
    },
    sourceData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showAddThirdParty: false
  }),

  computed: {
    ...mapGetters('factoring', ['factoringProductAttributes']),
    itemListeners () {
      const { remove, edit } = this.$listeners

      return pickBy({ remove, edit })
    },
    createListener () {
      const { create } = this.$listeners

      return pickBy({ submit: create })
    },
    fields () {
      return [
        {
          key: 'thirdPartyId',
          label: this.$t('thirdPartyId')
        },
        {
          key: 'thirdParty',
          label: this.$t('thirdParty')
        },
        {
          key: 'mainContact',
          label: this.$t('mainContact')
        },
        {
          key: 'startDate',
          label: this.$t('startDate')
        },
        {
          key: 'endDate',
          label: this.$t('endDate')
        },
        {
          key: 'limit',
          label: this.$t('limit'),
          class: 'money'
        },
        {
          key: 'availableLimit',
          label: this.$t('limitAvailable'),
          class: 'money'
        },
        {
          key: 'advanceRate',
          label: this.$t('advanceRate')
        },
        {
          key: 'invoiceLength',
          label: this.$t('invoiceLength')
        },
        {
          key: 'interestTerms',
          label: this.$t('interestTerms')
        },
        {
          key: 'referenceNumber',
          label: this.$t('reference')
        },
        {
          key: 'lastUpdated',
          label: this.$t('lastUpdated')
        },
        {
          key: 'notes',
          label: this.$t('notes')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        {}
      ]
    }
  }
}
</script>


<i18n>
en:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  limitUsed:      "Used limit"
  limitAvailable: "Available limit"
  advanceRate:    "Advance rate"
  invoiceLength:  "Invoice length (days)"
  lastUpdated:    "Last updated"
  interestTerms:  "Interest terms"
  reference:      "Reference #"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "No third-parties"
  thirdPartyId:   "Third party ID"
et:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  limitUsed:      "Used limit"
  limitAvailable: "Available limit"
  advanceRate:    "Advance rate"
  invoiceLength:  "Invoice length (days)"
  lastUpdated:    "Last updated"
  interestTerms:  "Interest terms"
  reference:      "Reference #"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "No third-parties"
  thirdPartyId:   "Third party ID"
ru:
  title:          "Third party agreements"
  thirdParty:     "Third party"
  mainContact:    "Main contact"
  limit:          "Limit"
  limitUsed:      "Used limit"
  limitAvailable: "Available limit"
  advanceRate:    "Advance rate"
  invoiceLength:  "Invoice length (days)"
  lastUpdated:    "Last updated"
  interestTerms:  "Interest terms"
  reference:      "Reference #"
  startDate:      "Start date"
  endDate:        "End date"
  notes:          "Notes"
  status:         "Status"
  notFound:       "No third-parties"
  thirdPartyId:   "Third party ID"
</i18n>
