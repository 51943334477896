<template lang="pug">
.factoring-communication
  v-loading(loader = 'customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    customer-communication-view(
      store-module           = 'factoring'
      resources-state        = 'factoringResources'
      resources-pages-state  = 'factoringResourcesPages'
      comments-state         = 'factoringComments'
      :resource-action       = 'resourceAction'
      commentable-type       = 'FACTORING_CONTRACT'
      messages-state         = 'factoringMessages'
      messages-pages-state   = 'factoringMessagesPages'
      customer-history-state = 'factoringCustomerHistory'
      customer-state         = 'factoringCustomer'
    )
</template>


<script>
import CustomerCommunicationView from '@/views/customers/CustomerCommunicationView'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'factoring-communication',

  components: { CustomerCommunicationView },

  computed: {
    ...mapState('factoring', ['factoringContract'])
  },

  created () {
    this.loadCustomerHistory({ customerId: this.factoringContract.customerBorrowerId })
  },

  methods: {
    ...mapActions('factoring', ['createFactoringResource', 'loadCustomerHistory']),
    resourceAction ({ resourceData }) {
      return this.createFactoringResource({
        resourceData: {
          ...resourceData,
          documentRelation: 'FACTORING_CONTRACT'
        },
        contractId: this.factoringContract.id,
        customerId: this.factoringContract.customerBorrowerId
      })
    }
  }
}
</script>
