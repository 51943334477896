<template lang="pug">
tr.factoring-invoice-list-item
  td: router-link(:to = '{ name: "FactoringContractInvoiceList", params: { id: invoice.contractId.toString()} }') {{ invoice.factoringInvoiceId }}
  td
    button.btn.btn-link.py-0(@click = 'showDetailsModal = true') {{ invoice.invoiceNumber }}
  td.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: invoice.thirdPartyCustomerId } }') {{ invoice.thirdPartyCustomer.fullName }}
  td: router-link(:to = '{ name: "FactoringContract", params: { id: invoice.contractId.toString()} }') {{ invoice.agreementNumber }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.dueDate }) }}
  td.money {{ invoice.invoiceAmount | money }}
  td.money {{ invoice.advanceAmount | money }}
  td.money {{ invoice.paidOutAmount | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paidOutDate }) }}
  td: .badge(:class = '`badge-${factoringInvoiceStatusColor(invoiceStatus.id)}`') {{ invoiceStatus.human }}
  td.money {{ invoice.reserveAmount | money }}
  td.money {{ invoice.receivedAmount || 0 | money }}
  td.money {{ invoice.receivableSum | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.deadline }) }}
  td.money {{ invoice.paid | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(scheduleStatus)}`') {{ scheduleStatus.human }}

  factoring-contract-invoice-details-modal(
    v-if        = 'showDetailsModal'
    v-model     = 'showDetailsModal'
    :loader     = '$vueLoading.isLoading(`factoring:invoice:imported:${invoice.factoringInvoiceId}:fetch`)'
    :invoice-id = 'invoice.factoringInvoiceId'
  )
</template>


<script>
import { mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import FactoringContractInvoiceDetailsModal from '@/views/factoring/FactoringContractInvoiceDetailsModal'

export default {
  name: 'factoring-invoice-list-item',

  components: { FactoringContractInvoiceDetailsModal, FiSwitch },

  props: {
    invoice: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showDetailsModal: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    ...mapGetters('factoring', ['factoringInvoiceStatusColor']),
    invoiceStatus () {
      return this.classifierById(this.invoice.factoringInvoiceStatus?.classifier, this.invoice.factoringInvoiceStatus?.id)
    },
    scheduleStatus () {
      return this.classifierById(this.invoice.status?.classifier, this.invoice.status?.id)
    }
  }
}
</script>
