<template lang="pug">
tr.factoring-contract-list-item
  td: router-link(:to = '{ name: "FactoringContract", params: { id: contract.factoringId.toString()} }') {{ contract.agreementNumber }}
  td: router-link(:to = '{ name: "Customer", params: { id: contract.customerId }}') {{ name }} ({{ contract.customer.idCode }})
  td {{ product.name }}
  td.money {{ contract.balance | money }}
  td.money {{ contract.contractLimit | money }}
  td.money {{ contract.debts | money }}
  td {{ contract.referenceNumber }}
  td.text-nowrap {{ $t('common:formatDate', { value: contract.periodStart }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: contract.periodEnd }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: contract.nextPaymentDate }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: contract.signingDate }) }}
  td: router-link(v-if = 'contract.managerId'
    :to  = '{ name: "Customer", params: { id: contract.managerId }}') {{ manager.name }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'
import { customerName } from '@/helpers'

export default {
  name: 'factoring-contract-list-item',

  props: {
    contract: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    ...mapGetters('products', ['productById']),
    ...mapGetters('loans', ['loanStatusColor']),
    product () {
      return this.productById(this.contract.productId)
    },
    status () {
      return this.classifierById(this.contract.status.classifier, this.contract.status.id)
    },
    name () {
      return customerName(this.contract.customer)
    },
    manager () {
      return this.managerById(this.contract.managerId)
    }
  }
}
</script>
