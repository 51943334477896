<template lang="pug">
tr.factoring-contract-invoice-list-item
  td
    .custom-control.custom-checkbox
      input.custom-control-input(
        v-model = 'selected'
        :id     = '`factoring-imported-invoice-item-${invoice.invoiceNumber}`'
        type    = 'checkbox'
        :value  = 'invoice.factoringInvoiceId'
      )
      label.custom-control-label(:for = '`factoring-imported-invoice-item-${invoice.invoiceNumber}`')
  td
    button.btn.btn-link.py-0(@click = 'showEditInvoice = true') {{ invoice.invoiceNumber }}
  td.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: invoice.thirdPartyCustomerId } }') {{ invoice.thirdPartyCustomer.fullName }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.dueDate }) }}
  td.money {{ invoice.invoiceAmount | money }}
  td.money {{ invoice.advanceAmount | money }}
  td.money {{ invoice.paidOutAmount | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paidOutDate }) }}
  td: .badge(:class = '`badge-${factoringInvoiceStatusColor(invoiceStatus.id)}`') {{ invoiceStatus.human }}
  td.money {{ invoice.reserveAmount || 0 | money }}
  td.money {{ invoice.receivedAmount || 0 | money }}
  td.money {{ invoice.receivableSum || 0 | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.deadline }) }}
  td.money {{ invoice.paid | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(scheduleStatus)}`') {{ scheduleStatus.human }}
  factoring-contract-invoice-edit-modal(
    v-if           = 'showEditInvoice'
    v-model        = 'showEditInvoice'
    :loader        = '$vueLoading.isLoading(`factoring:invoice:imported:${invoice.factoringInvoiceId}:save`)'
    :invoice       = 'invoice'
    :fee-configs    = 'feeConfigs'
    :third-parties = 'thirdParties'
    @submit        = '$listeners.edit'
    @upload        = '$listeners.upload'
  )
</template>


<script>
import { mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import FactoringContractInvoiceEditModal from '@/views/factoring/FactoringContractInvoiceEditModal'

export default {
  name: 'factoring-contract-invoice-list-item',

  components: { FactoringContractInvoiceEditModal, FiSwitch },

  props: {
    invoice: {
      type: Object,
      required: true
    },
    thirdParties: {
      type: Array,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    showEditInvoice: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    ...mapGetters('factoring', ['factoringInvoiceStatusColor']),
    invoiceStatus () {
      return this.classifierById(this.invoice.factoringInvoiceStatus?.classifier, this.invoice.factoringInvoiceStatus?.id)
    },
    scheduleStatus () {
      return this.classifierById(this.invoice.status?.classifier, this.invoice.status?.id)
    },
    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
