<template lang="pug">
.factoring-contract-terms-view
  v-loading(:loader = '["factoring:contract:terms:fetch", "factoring:customer:fetch"]')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    factoring-contract-terms-list(
      v-if         = 'showTerms'
      v-model      = 'selectedTerm'
      :terms       = 'factoringContractTerms'
      :fees        = 'possibleFees'
      :contract    = 'factoringContract'
      :fee-configs = 'feeConfigs'
      @create      = 'createTerm'
      @remove      = 'deleteTerm'
      @accept      = 'acceptTerm'
      @reason      = 'saveTermChangeReason'
      @terminate   = 'terminateFactoring'
    )
    factoring-contract-agreement-terms(
      :term        = 'selectedTerm'
      :product     = 'factoringProduct'
      :is-editable = 'isTermPending'
    )
    .row
      .col-6
        loan-terms-relations(
          :editable  = 'true'
          :relations = 'factoringRelations'
          @delete    = 'deleteRelation'
          @save      = 'saveRelation'
        )
        contract-terms-representatives-list(
          v-if            = 'factoringCustomer'
          :relations      = 'referredRelations'
          :customer       = 'factoringCustomer'
          @relation-saved = 'loadCustomerRelations'
        )
      .col-6
        contract-custom-fields-data(
          v-if           = '!isLoading'
          :custom-fields = 'customFieldsValues'
          :options       = 'factoringCustomFields'
          loader         = 'factoring:custom-fields:save'
          @submit        = 'saveCustomFields'
        )
        loan-payment-relation-list(
          :payment-relations = 'factoringPaymentRelations'
          @create            = 'addPaymentRelation'
          @save              = 'savePaymentRelation'
          @remove            = 'removePaymentRelation'
        )
    factoring-contract-third-party-list(
      :third-parties = 'factoringThirdParties'
      :source-data   = 'selectedTerm'
      full
      @edit          = 'updateThirdParty'
      @create        = 'addThirdParty'
    )
</template>


<script>
import FactoringContractTermsList from '@/views/factoring/FactoringContractTermsList'
import { mapActions, mapGetters, mapState } from 'vuex'
import { loanStatuses } from '@/const'
import FactoringContractAgreementTerms from '@/views/factoring/FactoringContractAgreementTerms'
import FactoringContractThirdPartyList from '@/views/factoring/FactoringContractThirdPartyList'
import LoanTermsRelations from '@/views/loans/LoanTerms/LoanTermsRelations'
import LoanPaymentRelationList from '@/views/loans/LoanTerms/LoanPaymentRelationList'
import ContractTermsRepresentativesList from '@/views/loans/LoanTerms/ContractTermsRepresentativesList'
import ContractCustomFieldsData from '@/views/loans/LoanTerms/ContractCustomFieldsData'

export default {
  name: 'factoring-contract-terms-view',

  components: {
    ContractCustomFieldsData,
    ContractTermsRepresentativesList,
    LoanPaymentRelationList,
    LoanTermsRelations,
    FactoringContractThirdPartyList,
    FactoringContractAgreementTerms,
    FactoringContractTermsList
  },

  data: () => ({
    selectedTermId: null,
    isLoading: true
  }),

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('factoring',
      [
        'factoringContractTerms',
        'factoringContract',
        'factoringProduct',
        'factoringRelations',
        'factoringPaymentRelations',
        'factoringThirdParties',
        'factoringCustomer',
        'factoringCustomerRelations',
        'customFieldsValues'
      ]),
    ...mapState('customFields', ['customFields']),
    selectedTerm: {
      get () {
        return this.factoringContractTerms.find(({ id }) => id === this.selectedTermId) ?? this.factoringContractTerms[0] ?? this.factoringContract?.term
      },
      set ({ id }) {
        this.selectedTermId = id
      }
    },
    showTerms () {
      const { active, matured, terminated, delayed } = loanStatuses
      return [active, matured, terminated, delayed].includes(this.factoringContract.status.code)
    },
    isTermPending () {
      return /pending/i.test(this.selectedTerm.status.code)
    },
    possibleFees () {
      return this.factoringProduct?.fees?.filter(({ feeType }) => !['AGREEMENT'].includes(feeType.code))
    },
    referredRelations () {
      return this.factoringCustomerRelations?.referredRelations
    },
    factoringCustomFields () {
      return this.factoringProduct?.customFields?.map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    }
  },

  async created () {
    await this.loadFactoringContractTerms({ id: this.$route.params.id })
    await this.loadCustomFields()
    await this.loadFactoringCustomFields({ contractId: this.$route.params.id, productGroup: 'FACTORING' })
    await this.loadFactoringRelations({ contractId: this.$route.params.id })
    await this.loadFactoringPaymentRelations({ contractId: this.$route.params.id })
    await this.loadFactoringThirdParties({ contractId: this.$route.params.id })
    await this.loadFeeConfig({ group: 'FACTORING' })
    this.isLoading = false
  },

  methods: {
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('factoring',
      [
        'loadFactoringContractTerms',
        'createFactoringContractPendingTerm',
        'activateFactoringContractPendingTerm',
        'removeFactoringContractTerm',
        'loadFactoringRelations',
        'createFactoringRelation',
        'updateFactoringRelation',
        'removeFactoringRelation',
        'loadFactoringPaymentRelations',
        'createFactoringPaymentRelation',
        'removeFactoringPaymentRelation',
        'updateFactoringPaymentRelation',
        'loadFactoringThirdParties',
        'updateFactoringThirdParty',
        'createFactoringThirdParty',
        'terminateFactoringContract',
        'loadCustomerRelations',
        'loadFactoringCustomFields',
        'saveFactoringCustomFields',
        'saveFactoringChangeReason'
      ]),
    ...mapActions('customFields', ['loadCustomFields']),
    createTerm () {
      this.createFactoringContractPendingTerm({ contractId: this.factoringContract.id })
    },
    deleteTerm (termId) {
      this.removeFactoringContractTerm({ contractId: this.factoringContract.id, termId })
    },
    async acceptTerm ({ done, payload }) {
      await this.activateFactoringContractPendingTerm({ contractId: this.factoringContract.id, data: payload })
      done && done()
    },
    async saveTermChangeReason (changeReasonComment) {
      await this.saveFactoringChangeReason({ contractId: this.factoringContract.id, termId: changeReasonComment.termId, changeReasonComment: changeReasonComment.reason })
      this.$v.$reset()
    },
    async updateThirdParty ({ thirdPartyId, thirdPartyData, done } = {}) {
      await this.updateFactoringThirdParty({
        contractId: this.factoringContract.id,
        thirdPartyData,
        thirdPartyId
      })

      done && done()
    },
    async addThirdParty ({ thirdPartyData, done }) {
      await this.createFactoringThirdParty({
        contractId: this.factoringContract.id,
        thirdPartyData
      })

      done && done()
    },
    deleteRelation ({ relationId }) {
      this.removeFactoringRelation({ contractId: this.factoringContract.id, relationId })
    },
    async saveRelation ({ relationId, done, relation }) {
      if (relationId) {
        await this.updateFactoringRelation({ contractId: this.factoringContract.id, relationData: relation, relationId })
      } else {
        await this.createFactoringRelation({ contractId: this.factoringContract.id, relationData: relation })
      }
      done && done()
    },
    async addPaymentRelation ({ done, paymentRelationData }) {
      await this.createFactoringPaymentRelation({
        contractId: this.factoringContract.id,
        relationData: paymentRelationData
      })

      done && done()
    },
    removePaymentRelation (relationId) {
      this.removeFactoringPaymentRelation({ contractId: this.factoringContract.id, relationId })
    },
    async savePaymentRelation ({ done, paymentRelationData, paymentRelationId }) {
      await this.updateFactoringPaymentRelation({
        contractId: this.factoringContract.id,
        relationId: paymentRelationId,
        relationData: paymentRelationData
      })
      done && done()
    },
    async terminateFactoring ({ data, done }) {
      await this.terminateFactoringContract({ contractId: this.factoringContract.id, data })
      done && done()
    },
    async saveCustomFields ({ data, done }) {
      await this.saveFactoringCustomFields({ contractId: this.factoringContract.id, customFieldData: data, productGroup: 'FACTORING' })
      done()
    }
  }

}
</script>
