<template lang="pug">
fi-table.factoring-contract-overview-schedule-invoice-list(
  :fields      = 'fields'
  :items       = 'invoices'
  :empty-label = '$t("notFound")'
  loader       = 'factoring:contract:invoices:schedule:fetch'
  responsive
)
  template(#default = '{ items }')
    factoring-contract-overview-schedule-invoice-list-item(
      v-for    = 'invoice in items'
      :key     = 'invoice.id'
      :invoice = 'invoice'
    )
  template(#custom-footer)
    tr.font-weight-bold
      td(colspan=4)
      td.money {{ totalValues.principal | money }}
      td.money {{ totalValues.interest | money }}
      td.money {{ totalValues.fee | money }}
      td.money {{ totalValues.penaltyAmount | money }}
      td.money {{ sum | money }}
      td.money {{ totalValues.amountPaid | money }}
      td(colspan=3)
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringContractOverviewScheduleInvoiceListItem
  from '@/views/factoring/FactoringContractOverviewScheduleInvoiceListItem'
import { mapActions } from 'vuex'

export default {
  name: 'factoring-contract-overview-schedule-invoice-list',

  components: { FactoringContractOverviewScheduleInvoiceListItem, FiTable },

  props: {
    invoices: {
      type: Array,
      required: true
    }
  },

  computed: {
    fields () {
      return [
        {
          label: this.$t('invoiceNumber'),
          class: 'text-wrap'
        },
        {
          label: this.$t('date')
        },
        {
          label: this.$t('deadline')
        },
        {
          label: this.$t('balance'),
          class: 'money'
        },
        {
          label: this.$t('principal'),
          class: 'money'
        },
        {
          label: this.$t('interest'),
          class: 'money'
        },
        {
          label: this.$t('fees'),
          class: 'money'
        },
        {
          label: this.$t('penalty'),
          class: 'money'
        },
        {
          label: this.$t('sum'),
          class: 'money'
        },
        {
          label: this.$t('paid'),
          class: 'money'
        },
        {
          label: this.$t('paidDate')
        },
        {
          label: this.$t('status')
        },
        {}
      ]
    },
    totalValues () {
      return this.invoices.reduce((res, { principal = 0, interest = 0, fee = 0, amountPaid = 0, penaltyAmount = 0 }) => ({
        principal: (res.principal ?? 0) + principal,
        interest: (res.interest ?? 0) + interest,
        fee: (res.fee ?? 0) + fee,
        penaltyAmount: (res.penaltyAmount ?? 0) + penaltyAmount,
        amountPaid: (res.amountPaid ?? 0) + amountPaid
      }), {})
    },
    sum () {
      return Object.values(this.totalValues).reduce((sum, value) => sum + value, 0)
    }
  },

  created () {
    this.loadFactoringContractScheduleInvoices({ contractId: this.$route.params.id })
  },

  methods: mapActions('factoring', ['loadFactoringContractScheduleInvoices'])
}

</script>


<i18n>
en:
  invoiceNumber: "Invoice #"
  date:          "Date"
  deadline:      "Deadline"
  balance:       "Balance"
  principal:     "Principal"
  interest:      "Interest"
  fees:          "Fees"
  penalty:       "Penalty"
  sum:           "Sum"
  paid:          "Paid"
  paidDate:      "Paid date"
  status:        "Status"
  notFound:      "Invoices not found"
et:
  invoiceNumber: "Invoice #"
  date:          "Date"
  deadline:      "Deadline"
  balance:       "Balance"
  principal:     "Principal"
  interest:      "Interest"
  fees:          "Fees"
  penalty:       "Penalty"
  sum:           "Sum"
  paid:          "Paid"
  paidDate:      "Paid date"
  status:        "Status"
  notFound:      "Invoices not found"
ru:
  invoiceNumber: "Invoice #"
  date:          "Date"
  deadline:      "Deadline"
  balance:       "Balance"
  principal:     "Principal"
  interest:      "Interest"
  fees:          "Fees"
  penalty:       "Penalty"
  sum:           "Sum"
  paid:          "Paid"
  paidDate:      "Paid date"
  status:        "Status"
  notFound:      "Invoices not found"
</i18n>
