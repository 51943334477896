<template lang="pug">
tr.factoring-contract-terms-list-item(
  :class = '{ "table-active": active }'
  @click = 'emitSelect'
)
  td {{ $t('common:formatDate', { value: term.periodStart }) }}
  td {{ $t('common:formatDate', { value: term.periodEnd }) }}
  td.text-nowrap
    .input-group(v-if = '(status.name === "PENDING") && active')
      input.form-control.form-control-sm(
        v-model.trim = 'reasonModel'
        @click.stop
      )
      .input-group-append
        button.btn.btn-primary.p-1(
          v-if = 'value.$dirty'
          @click.stop = '$emit("reason", value.$model)'
        ) {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("factoring:term:reason:save")')
    template(v-else) {{ term.comment }}
    td {{ term.updatedBy }}
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
</template>

<script>
import { mapGetters } from 'vuex'

const COLOR_MAP = {
  PENDING: 'primary',
  COMPLETE: 'secondary',
  ACTIVE: 'success'
}

export default {
  name: 'factoring-contract-terms-list-item',

  props: {
    term: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    status () {
      return this.classifierById(this.term.status.classifier, this.term.status.id)
    },
    reasonModel: {
      get () {
        return this.value.$model
      },
      set (reason) {
        this.$emit('input', reason)
      }
    },
    statusClass () {
      return COLOR_MAP[this.term.status.id]
    }
  },

  methods: {
    emitSelect () {
      this.$emit('select', this.term)
    }
  }
}
</script>


<style scoped>
.factoring-contract-terms-list-item {
  cursor: pointer;
}
</style>
