<template lang="pug">
.factoring-contract-schedule-invoice-list.animated.fadeIn
  .card
    nav.card-header
      b-nav(card-header tabs)
        b-nav-item(:to = '{ name: "FactoringContractInvoiceList" }') {{ $t('menu:factoringInvoices') }}
        b-nav-item(:to = '{ name: "FactoringContractScheduleInvoiceList" }') {{ $t('menu:factoringScheduleInvoices') }}
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg
            range-input-date(
              :options     = 'rangeInputDateOptions'
              :start.sync  = 'searchParams.dateMin'
              :end.sync    = 'searchParams.dateMax'
              :column.sync = 'searchParams.dateColumn'
            )
          .form-group.col-lg
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-select(
                v-model.number = 'searchParams.schInvoiceStatuses'
                :options       = 'optionsFromClassifier("LoanInvoiceStatus", true)'
                :placeholder   = '$t("scheduleStatusesPlaceholder")'
              )
      fi-table(
        :fields      = 'fields'
        :items       = 'scheduleInvoiceList'
        loader       = 'factoring:contract:invoices:schedule:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          factoring-contract-overview-schedule-invoice-list-item.animated.fadeIn(
            v-for          = 'invoice in items'
            :key           = 'invoice.id'
            :invoice       = 'invoice'
            :editable.sync = 'editable'
            @save          = 'saveFactoringInvoiceSchedule'
          )
      fi-pagination(
        v-if    = 'scheduleInvoiceListPages > 1'
        :pages  = 'scheduleInvoiceListPages'
        v-model = 'pagination.page'
      )
</template>


<script>
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import FiPagination from '@/components/FiPagination'
import { mapActions, mapGetters, mapState } from 'vuex'
import FactoringContractOverviewScheduleInvoiceListItem
  from '@/views/factoring/FactoringContractOverviewScheduleInvoiceListItem'

const searchParams = {
  criteria: '',
  dateColumn: 'invoiceDate',
  dateMin: null,
  dateMax: null,
  schInvoiceStatuses: ''
}

export default {
  name: 'factoring-contract-schedule-invoice-list',

  components: { FactoringContractOverviewScheduleInvoiceListItem, FiPagination, FiTable, FiSelect, RangeInputText, RangeInputDate },

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  data: (vm) => ({
    editable: true,
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), searchQuery: vm.criteria },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('factoring', ['scheduleInvoiceList', 'scheduleInvoiceListPages']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    },
    rangeInputDateOptions () {
      return {
        invoiceDate: this.$t('invoiceDate'),
        deadline: this.$t('deadline')
      }
    },
    rangeInputTextOptions () {
      return {
        invoiceAmount: this.$t('invoiceAmount'),
        paidOutAmount: this.$t('paidOutAmount')
      }
    },
    fields () {
      return [
        {
          key: 'invoiceNumber',
          label: this.$t('invoiceNumber')
        },
        {
          key: 'invoiceDate',
          label: this.$t('invoiceDate')
        },
        {
          key: 'deadline',
          label: this.$t('deadline')
        },
        {
          key: 'balance',
          label: this.$t('balance'),
          class: 'money'
        },
        {
          key: 'principal',
          label: this.$t('principal'),
          class: 'money'
        },
        {
          key: 'interest',
          label: this.$t('interest'),
          class: 'money'
        },
        {
          key: 'fees',
          label: this.$t('fees'),
          class: 'money'
        },
        {
          key: 'penalty',
          label: this.$t('penalty'),
          class: 'money'
        },
        {
          key: 'sum',
          label: this.$t('sum'),
          class: 'money'
        },
        {
          key: 'paid',
          label: this.$t('paid'),
          class: 'money'
        },
        {
          key: 'paidDate',
          label: this.$t('paidDate')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        {}
      ]
    },
    searchFilter () {
      const filter = { ...this.searchParams }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      return filter
    },
    allSelected () {
      return this.selectedItems?.length && this.selectedItems?.length === this.itemsIds?.length
    },
    itemsIds () {
      return this.importedInvoiceList.map(({ id }) => id) || []
    },
    indeterminate () {
      const { length } = this.selectedItems
      return Boolean(length) && (length < this.itemsIds?.length)
    }
  },

  watch: {
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    '$route.query': {
      handler (query) {
        this.loadFactoringContractScheduleInvoices({ ...this.searchQuery, ...query, contractId: this.id })
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    this.loadProducts({ group: 'FACTORING' })
  },

  methods: {
    ...mapActions('factoring', ['loadFactoringContractScheduleInvoices', 'updateFactoringScheduleInvoiceDeadline']),
    ...mapActions('products', ['loadProducts']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    ),
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.messageIds
      } else {
        this.selectedItems = []
      }
    },
    async saveFactoringInvoiceSchedule ({ done, ...payload }) {
      await this.updateFactoringScheduleInvoiceDeadline({ ...payload, contractId: this.id })
      // eslint-disable-next-line no-unused-expressions
      done?.()
      this.editable = true
    }
  }
}
</script>


<i18n>
en:
  searchPlaceholder:           "Search by third-party name"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  invoiceDate:                 "Date"
  balance:                     "Balance"
  principal:                   "Principal"
  interest:                    "Interest"
  fees:                        "Fees"
  penalty:                     "Penalty"
  sum:                         "Sum"
  notFound:                    "Invoices not found"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
et:
  searchPlaceholder:           "Search by third-party name"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  invoiceDate:                 "Date"
  balance:                     "Balance"
  principal:                   "Principal"
  interest:                    "Interest"
  fees:                        "Fees"
  penalty:                     "Penalty"
  sum:                         "Sum"
  notFound:                    "Invoices not found"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
ru:
  searchPlaceholder:           "Search by third-party name"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  invoiceDate:                 "Date"
  balance:                     "Balance"
  principal:                   "Principal"
  interest:                    "Interest"
  fees:                        "Fees"
  penalty:                     "Penalty"
  sum:                         "Sum"
  notFound:                    "Invoices not found"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
</i18n>
