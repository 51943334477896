<template lang="pug">
tr.factoring-contract-overview-invoices-list-item
  td {{ invoice.invoiceNumber }}
  td.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: invoice.thirdPartyCustomerId } }') {{ invoice.thirdPartyCustomer.fullName }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.dueDate }) }}
  td.money {{ invoice.invoiceAmount | money }}
  td.money {{ invoice.advanceAmount | money }}
  td.money {{ invoice.paidOutAmount | money }}
  td: .badge(:class = '`badge-${loanStatusColor(status.code)}`') {{ status.human }}
  td.money {{ invoice.receivableSum | money }}
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.deadline }) }}
  td.money {{ invoice.paid || 0 | money }}
  td.money {{ invoice.reserve | money }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'factoring-contract-overview-invoices-list-item',

  props: {
    invoice: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanStatusColor']),
    status () {
      const { code, classifier } = this.invoice.factoringInvoiceStatus
      return this.classifierById(classifier, code)
    }
  }
}
</script>
