<template lang="pug">
.factoring-contract-terms-list.card
  .card-header {{ $t('title') }}
  .card-body
    fi-table.pb-2(
      :fields = 'fields'
      :items  = 'terms'
    )
      template(#default = '{ items }')
        factoring-contract-terms-list-item(
          v-for   = 'term in items'
          :value  = '$v.reasonCommentModel'
          :key    = 'term.id'
          :term   = 'term'
          :active = 'term.id === selectedTerm.id'
          @select = '$listeners.select'
          @input  = '$v.reasonCommentModel.$model = $event'
          @reason = 'saveReason'
        )
    template(v-if = 'selectedTermStatus === "PENDING"')
      button.btn.btn-success(
        :disabled    = 'accepting'
        @click       = 'prepareAccept'
      ) {{ $t('confirmDraft') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'accepting')
      | &nbsp;
      button.btn.btn-danger(
        :disabled = 'removing'
        @click    = 'removeTerm'
      ) {{ $t('removeDraft') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')

    template(v-else-if = 'selectedTermStatus === "ACTIVE"')
      button.btn.btn-primary(
        :disabled = 'creating'
        @click    = 'createTerm'
      ) {{ $t('createDraft') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'creating')

    .fa-pull-right
      button.btn.btn-success.ml-2(
        :disabled = 'creating'
        @click    = 'terminationModal = true'
      ) {{ $t('terminate') }}
  fi-term-accept-modal(
    v-if               = 'showAcceptModal'
    v-model            = 'showAcceptModal'
    :fee-types         = 'fees'
    :contract-amount   = 'contract.term.contractLimit'
    :fee-configs       = 'feeConfigs'
    loader             = 'factoring:contract:term:activate:save'
    @save              = '$listeners.accept'
  )
  factoring-termination-modal(
    v-if             = 'terminationModal'
    v-model          = 'terminationModal'
    :loader          = 'creating'
    :termination-fee = 'terminationFee'
    :contract-amount = 'contract.term.contractLimit'
    :fee-configs      = 'feeConfigs'
    :period-start    = 'contract.term.periodStart'
    :period-end      = 'contract.term.periodEnd'
    @submit          = 'terminate'
  )
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringContractTermsListItem from '@/views/factoring/FactoringContractTermsListItem'
import { validation, validators } from '@/validation'
import FiTermAcceptModal from '@/components/FiTermAcceptModal'
import FactoringTerminationModal from '@/views/factoring/FactoringTerminationModal'

export default {
  name: 'factoring-contract-terms-list',

  components: { FactoringTerminationModal, FiTermAcceptModal, FactoringContractTermsListItem, FiTable },

  mixins: [validation],

  model: {
    prop: 'selectedTerm',
    event: 'select'
  },

  props: {
    terms: {
      type: Array,
      required: true
    },
    selectedTerm: {
      type: Object,
      default: () => ({})
    },
    fees: {
      type: Array,
      default: () => []
    },
    contract: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data: () => ({
    reasonComment: '',
    showAcceptModal: false,
    terminationModal: false
  }),

  computed: {
    fields () {
      return [
        {
          label: this.$t('validFrom')
        },
        {
          label: this.$t('validUntil')
        },
        {
          label: this.$t('changeReason')
        },
        {
          label: this.$t('createdBy')
        },
        {
          label: this.$t('status')
        }
      ]
    },
    reasonCommentModel: {
      get () {
        return this.reasonComment ?? this.selectedTerm.comment
      },
      set (reason) {
        this.reasonComment = reason
        // localStorage.setItem(`factoring:term${this.selectedTerm.id}:reason`, reason)
      }
    },
    selectedTermStatus () {
      return this.selectedTerm.status?.code
    },
    accepting () {
      return this.$vueLoading.isLoading('factoring:contract:term:activate:save')
    },
    removing () {
      return this.$vueLoading.isLoading('factoring:contract:term:remove:save')
    },
    creating () {
      return this.$vueLoading.isLoading('factoring:contract:term:copy:save')
    },
    terminationFee () {
      return this.fees.find(({ feeType }) => feeType.code === 'TERMINATION') ?? {}
    }
  },

  watch: {
    reasonComment: {
      handler (newComment) {
        if (!this.reasonComment.length) {
          this.reasonComment = this.selectedTerm.comment
        }
        const { comment } = this.selectedTerm
        if (newComment !== comment) {
          this.$v.$touch()
        }
      },
      immediate: true
    }
  },

  validations: {
    reasonCommentModel: {
      required: validators.required,
      maxLength: validators.maxLength(50)
    }
  },

  methods: {
    createTerm () {
      this.$emit('create')
    },
    removeTerm () {
      this.$emit('remove', this.selectedTerm.id)
    },
    saveReason (reason) {
      this.$v.$reset()
      this.$emit('reason', { reason, termId: this.selectedTerm.id })
      localStorage.removeItem(`factoring:term${this.selectedTerm.id}:reason`)
    },
    terminate (payload) {
      this.$emit('terminate', payload)
    },
    prepareAccept () {
      if (this.selectedTerm.pendingForTermination) {
        this.$emit('accept', {})
      } else {
        this.showAcceptModal = true
      }
    }
  }
}
</script>


<i18n>
en:
  title:        "Terms history"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  changeReason: "Term change reason"
  status:       "Status"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  createDraft:  "Change"
  confirm:      "Please confirm that Customer has reviewed change and signed it"
  terminate:    "Termination"
  createdBy:    "Created by"
et:
  title:        "Terms history"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  changeReason: "Term change reason"
  status:       "Status"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  createDraft:  "Change"
  confirm:      "Please confirm that Customer has reviewed change and signed it"
  terminate:    "Termination"
  createdBy:    "Created by"
ru:
  title:        "Terms history"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  changeReason: "Term change reason"
  status:       "Status"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  createDraft:  "Change"
  confirm:      "Please confirm that Customer has reviewed change and signed it"
  terminate:    "Termination"
  createdBy:    "Created by"
</i18n>
