<template lang="pug">
b-modal.factoring-contract-invoice-payout-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'xl'
  lazy
  @shown  = 'resetData()'
)
  fi-table(
    :fields = 'fields'
    :items  = 'payouts'
    responsive
  )
    template(#default = '{ items }')
      tr(v-for = '(item, index) in items')
        td {{ item.factoringInvoiceId }}
        td {{ item.invoiceNumber }}
        td.text-nowrap {{ $t('common:formatDate', { value: item.dueDate }) }}
        td.text-nowrap {{ item.thirdPartyCustomer.fullName }}
        td.money {{ item.invoiceAmount | money }}
        td.money {{ item.advanceAmount | money }}
        td.money {{ item.paidOutAmount | money }}
        td.money {{ item.availableLimit | money }}
        td.money
          fi-form-field(:state = '!$v.$invalid')
            template(#error)
              .error-message(v-if = '!$v.payouts.$each.$iter[index].payoutAmount.required') {{ $t('common:required') }}
              .error-message(v-if = '!$v.payouts.$each.$iter[index].payoutAmount.max') {{ $t('maxError', { value: $v.payouts.$each.$iter[index].payoutAmount.$params.max.param($v.payouts.$each.$iter[index].$model) }) }}
              .error-message(v-if = 'getMinValue(factoringContract.amountLimit, item.availableLimit) < item.payoutAmount') {{ $t('maxError', {value: getMinValue(factoringContract.amountLimit, item.availableLimit)}) }}
            fi-money-input.form-control-sm(
              v-model = 'item.payoutAmount'
              :class  = '{ "is-invalid": $v.payouts.$each.$iter[index].payoutAmount.$invalid }'
            )
        td
          fi-select(
            v-model     = 'item.paymentRelationId'
            :options    = 'paymentRelationOptions'
            :autoSelect = 'singleUserSelection'
            required
            sm
          )
        td
          button.btn.btn-outline-danger.btn-sm(@click = 'removeInvoice(index)')
            i.fas.fa-times
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import FiTable from '@/components/FiTable'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { validation, validators } from '@/validation'
import FiFormField from '@/components/FiFormField'

export default {
  name: 'factoring-contract-invoice-payout-modal',

  components: { FiFormField, FiSelect, FiMoneyInput, FiTable, FiModal },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    invoices: {
      type: Array,
      required: true
    },
    relations: {
      type: Array,
      required: true
    },
    factoringContract: {
      type: Object,
      required: true
    },
    loader: {
      type: String,
      default: ''
    },
    thirdParties: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    payouts: []
  }),

  computed: {
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    fields () {
      return [
        { label: 'ID' },
        { label: this.$t('invoiceNumber') },
        { label: this.$t('dueDate') },
        { label: this.$t('thirdParty') },
        {
          label: this.$t('invoiceAmount'),
          class: 'money'
        },
        {
          label: this.$t('advanceAmount'),
          class: 'money'
        },
        {
          label: this.$t('paidOutAmount'),
          class: 'money'
        },
        {
          label: this.$t('limit'),
          class: 'money'
        },
        {
          label: this.$t('payoutAmount'),
          class: 'money'
        },
        { label: this.$t('beneficiaryName') },
        {}
      ]
    },
    paymentRelationOptions () {
      return this.relations.map(({ beneficiaryName, relationId }) => ({
        value: relationId,
        text: beneficiaryName
      }))
    },
    singleUserSelection () {
      if (this.relations.length === 1) {
        return true
      } else return false
    }
  },

  watch: {
    invoices: 'resetData'
  },

  validations () {
    return {
      payouts: {
        $each: {
          payoutAmount: {
            required: validators.required,
            max: validators.maxValueRef(vm => Math.min(vm.advanceAmount, vm.availableLimit, this.getNewAdvanceAmount(vm.advanceAmount, vm.availableLimit, vm.payoutAmount))),
            maxLimit: validators.maxValueRefWithZero(vm => Math.min(this.getMinValue(this.factoringContract.amountLimit, vm.availableLimit), vm.payoutAmount))
          }
        }
      }
    }
  },

  methods: {
    getMinValue (amountLimit, availableLimit) {
      const min = Math.min(amountLimit, availableLimit)
      return min < 0 ? 0 : min
    },
    getNewAdvanceAmount (advanceAmount, availableLimit, payoutAmount) {
      let sum = 0
      if (advanceAmount > availableLimit) {
        this.payouts.forEach((element) => {
          sum = sum + element.payoutAmount
        })
        sum = sum - payoutAmount
        return (availableLimit - sum).toFixed(2)
      }
      return advanceAmount
    },
    invoiceThirdParty (thirdPartyId) {
      return this.thirdParties.find(({ id }) => id === thirdPartyId)
    },
    resetData (invoices = this.invoices) {
      if (!invoices.length) this.$refs.modal.hide()
      this.payouts = invoices.map(invoice => ({
        ...invoice,
        payoutAmount: invoice.advanceAmount,
        paymentRelationId: this.relations.find(({ customerId }) => customerId === invoice.thirdPartyCustomerId)?.relationId
      }))
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        this.$emit('submit', {
          payoutData: this.payouts.map(({
            factoringInvoiceId,
            invoiceNumber,
            paymentRelationId,
            thirdPartyId,
            payoutAmount
          }) => ({
            invoiceId: factoringInvoiceId,
            thirdPartyId,
            invoiceNumber,
            payoutAmount,
            paymentRelationId
          })),
          done: () => this.$refs.modal.hide()
        })
      }
    },
    removeInvoice (index) {
      const newInvoices = [...this.invoices]
      newInvoices.splice(index, 1)
      this.$emit('update:invoices', newInvoices)
    }
  }
}
</script>


<i18n>
en:
  title:           "Factoring invoice payout"
  limit:           "Available limit"
  payoutAmount:    "Payout amount"
  beneficiaryName: "Beneficiary name"
  minError:        "Value should be greater or equal to {{ value }}"
  maxError:        "Value should be less or equal to {{ value }}"
et:
  title:           "Factoring invoice payout"
  limit:           "Available limit"
  payoutAmount:    "Payout amount"
  beneficiaryName: "Beneficiary name"
  minError:        "Value should be greater or equal to {{ value }}"
  maxError:        "Value should be less or equal to {{ value }}"
ru:
  title:           "Factoring invoice payout"
  limit:           "Available limit"
  payoutAmount:    "Payout amount"
  beneficiaryName: "Beneficiary name"
  minError:        "Value should be greater or equal to {{ value }}"
  maxError:        "Value should be less or equal to {{ value }}"
</i18n>
