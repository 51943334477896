<template lang="pug">
b-modal.factoring-contract-invoice-edit-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title", { status: status.human })'
  size    = 'xl'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    .row
      .col-lg-6
        fi-form-field(:label = '$t("thirdParty")')
          fi-select(
            v-model  = 'invoiceData.thirdPartyId'
            :options = 'thirdParties'
            sm
          )
        fi-form-field(:label = '$t("invoiceNr")')
          input.form-control.form-control-sm(v-model.trim = 'invoiceData.invoiceNumber')
        fi-form-field(:label = '$t("amount")')
          fi-money-input.form-control-sm(v-model = 'invoiceData.amount')
        fi-form-field(:label = '$t("dueDate")')
          fi-datepicker(
            v-model   = 'invoiceData.dueDate'
            :min-date = 'factoringImportedInvoice.invoiceDate'
            sm
          )
        fi-form-field(:label = '$t("period")') {{ invoicePeriod }}
        fi-form-field(
          :label = 'classifierByName("feeTypeEntity", invoiceData.processingFee.feeType).human'
          disabled
        )
          .form-row
            .col
              fi-select(
                v-model = 'invoiceData.processingFee.feeCalculationType'
                :options = 'feeCalculationTypeOptions'
                required
                sm
              )
            .col
              percent-input.form-control-sm.form-control(
                v-if = 'percentageProcessingFee'
                v-model = 'invoiceData.processingFee.feeAmount'
              )
              fi-money-input.form-control-sm.form-control(
                v-else
                v-model = 'invoiceData.processingFee.feeAmount'
              )
      .col-lg-6
        fi-form-field(:label = '$t("advanceRate")')
          .form-row
            .col-6
              percent-input.form-control-sm.form-control(
                v-model = 'factoringImportedInvoice.advanceRate'
                readonly
              )
            .col-6
              fi-money-input.form-control-sm.form-control(
                v-model = 'invoiceData.advanceAmount'
                :readonly = '!isUnapproved'
              )
        fi-form-field(:label = '$t("availableLimit")')
          fi-money-input.form-control-sm.form-control(
            v-model = 'factoringImportedInvoice.availableLimit'
            readonly
          )
        fi-form-field(:label = '$t("invoiceDate")')
          fi-datepicker(
            v-model = 'factoringImportedInvoice.invoiceDate'
            disabled
            sm
          )
        fi-form-field(:label = '$t("recourse")')
          fi-switch(
            v-model = 'invoiceData.recourse'
            :read-only = '!isRecourseEditable'
          )
        fi-form-field(:label = '$t("paidOutAmount")')
          button.btn.btn-link.py-0#paidAmount(@click.prevent) {{ factoringImportedInvoice.paidAmount | money }}
        b-popover(
          target   = 'paidAmount'
          triggers  = 'hover'
          placement = 'bottom'
        )
          table
            thead
              tr
                th {{ $t('date') }}
                th.money {{ $t('amount') }}
            tbody
              tr(v-for = 'payment in factoringImportedInvoice.payments')
                td {{ $t('common:formatDate', { value: payment.date }) }}
                td.pl-4.money {{ payment.amount | money }}
        fi-form-field(:label = '$t("receivedAmount")')
          fi-money-input.form-control-sm.form-control(v-model = 'invoiceData.receivedAmount')
        fi-form-field(:label = '$t("reserveAmount")')
          fi-money-input.form-control-sm.form-control(v-model = 'invoiceData.reserveAmount')
    .form-row
      .col
        h5.mt-4.border-bottom.pb-2 {{ $t('uploadDocument') }}
        fi-form-field(
          :label     = '$t("files")'
          label-cols = 2
        )
          .form-row
            .col-auto
              .input-group
                .input-group-prepend
                  span.input-group-text
                    i.fa.fa-file-alt
                fi-select(
                  v-model.number = 'resourceData.documentCategoryId'
                  :options       = 'optionsFromClassifier("DocumentCategoryType")'
                  required
                  sm
                )
            .col
              fi-file-input(
                v-model = 'files'
                size    = 'sm'
                multiple
              )
        fi-form-field(
          :label     = '$t("description")'
          label-cols = 2
        )
          textarea.form-control(v-model = 'resourceData.comment')
    .form-row
      .col-6
        fi-form-field(
          :label     = '$t("visibleForPortal")'
          label-cols = 4
        )
          fi-switch(v-model = 'resourceData.visibleForPortal')
      .col
        button.btn.btn-primary.fa-pull-right(@click.prevent = 'uploadResource') {{ $t('add') }}
    .form-row
      .col
        .card-header {{ $t('documents') }}
        .card-body
          fi-table(
            :fields      = 'documentFields'
            :items       = 'factoringDocuments'
            :empty-label = '$t("notFound")'
            :loader      = '`factoring:invoice:imported:${invoice.factoringInvoiceId}:fetch`'
          )
            template(#default = '{ items }')
              customer-document-list-item(
                v-for        = 'document in items'
                :key         = 'document.id'
                :resource    = 'document'
                :categories  = 'optionsFromClassifier("DocumentCategoryType")'
                store-module = 'factoring'
                short
                @changed     = 'loadFactoringImportedInvoice({ invoiceId: invoice.factoringInvoiceId })'
              )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSelect from '@/components/FiSelect'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiFormField from '@/components/FiFormField'
import pick from 'lodash/pick'
import FiSwitch from '@/components/FiSwitch'
import merge from 'lodash/merge'
import FiFileInput from '@/components/FiFileInput'
import FiTable from '@/components/FiTable'
import CustomerDocumentListItem from '@/views/customers/CustomerDocumentListItem'
import { feePercentage } from '@/helpers'

export default {
  name: 'factoring-contract-invoice-edit-modal',

  components: { CustomerDocumentListItem, FiTable, FiFileInput, FiSwitch, FiFormField, PercentInput, FiDatepicker, FiMoneyInput, FiSelect, FiModal },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Object,
      required: true
    },
    thirdParties: {
      type: Array,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    invoiceData: {
      recourse: false,
      thirdPartyId: '',
      invoiceNumber: null,
      amount: null,
      advanceAmount: null,
      receivedAmount: null,
      reserveAmount: null,
      dueDate: '',
      processingFee: {
        feeType: 'PROCESSING',
        feeCalculationType: undefined,
        feeAmount: ''
      }
    },
    files: [],
    resourceData: {
      comment: null,
      documentCategoryId: '',
      visibleForPortal: false
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'optionsFromClassifier']),
    ...mapState('factoring', ['factoringImportedInvoice', 'factoringContract']),
    activeThirdParty () {
      return this.thirdParties.find(({ id }) => id === this.thirdPartyId)
    },
    saving () {
      return this.loader
    },
    isUnapproved () {
      return /unapproved/i.test(this.factoringImportedInvoice?.status?.code)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    status () {
      return this.classifierById(this.factoringImportedInvoice?.status?.classifier, this.factoringImportedInvoice?.status?.code)
    },
    percentageProcessingFee () {
      return feePercentage(this.invoiceData?.processingFee.feeCalculationType)
    },
    feeCalculationTypeOptions () {
      const feeCalculationTypePossible = this.feeConfigs?.[this.invoiceData?.processingFee?.feeType]
      return this.optionsFromClassifier('feeCalculationType', true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    optionsFromStatuses () {
      return [
        {
          value: this.factoringImportedInvoice?.status?.code,
          text: this.classifierById(this.factoringImportedInvoice?.status?.classifier, this.factoringImportedInvoice?.status?.code).human,
          disabled: true
        },
        ...this.factoringImportedInvoice?.availableStatuses?.map(({ classifier, code }) => ({
          value: code,
          text: this.classifierById(classifier, code).human
        })) ?? []
      ]
    },
    documentFields () {
      return [
        {
          key: 'filename',
          label: this.$t('filename')
        },
        {
          key: 'documentCategoryId',
          label: this.$t('category')
        },
        {
          key: 'comment',
          label: this.$t('description')
        },
        {
          key: 'visibleForPortal',
          label: this.$t('visibleForPortal')
        },
        {}
      ]
    },
    factoringDocuments () {
      return this.factoringImportedInvoice?.documents ?? []
    },
    isRecourseEditable () {
      const { term } = this.factoringContract
      return term.rightOfRecourse
    },
    invoicePeriod () {
      const { invoiceDate } = this.factoringImportedInvoice
      const { dueDate } = this.invoiceData
      if (!invoiceDate || !dueDate) return
      return this.$moment.duration(this.$moment(invoiceDate).diff(this.$moment(dueDate ?? ''))).humanize({ d: 90 })
    }
  },

  watch: {
    'invoiceData.amount' (amount) {
      if (!this.isUnapproved) return
      this.invoiceData.advanceAmount = (amount * this.factoringImportedInvoice.advanceRate).toFixed(2)
    }
  },

  methods: {
    ...mapActions('factoring', ['loadFactoringImportedInvoice']),
    async resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.resourceData.documentCategoryId = this.classifierByName('DocumentCategoryType', 'FACTORING_INVOICE')?.id
      await this.loadFactoringImportedInvoice({ invoiceId: this.invoice.factoringInvoiceId })
      const { feeType, feeCalculationType, feeAmount } = this.factoringImportedInvoice.processingFee ?? {}
      this.invoiceData = {
        ...this.invoiceData,
        ...pick(this.factoringImportedInvoice, Object.keys(this.invoiceData)),
        processingFee: merge(this.invoiceData.processingFee, {
          feeType: feeType?.code,
          feeCalculationType: feeCalculationType?.code,
          feeAmount
        })
      }
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      this.$emit('submit', {
        invoiceId: this.invoice.factoringInvoiceId,
        invoiceData: this.invoiceData,
        done: () => this.$refs.modal.hide()
      })
    },
    uploadResource () {
      const { resourceData, files, invoice } = this
      this.$emit(
        'upload',
        {
          invoiceId: invoice.factoringInvoiceId,
          resourceData,
          files,
          customerId: invoice.thirdPartyCustomerId,
          done: () => this.resetData()
        }
      )
    }
  }
}
</script>


<i18n>
en:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  date:             "Date"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  receivedAmount:   "Received amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
  paidOutAmount:    "Paid out amount"
  uploadDocument:   "Upload document"
  files:            "Files"
  filename:         "Filename"
  category:         "Category"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  add:              "Add"
  documents:        "Documents"
  notFound:         "No documents found"
et:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  date:             "Date"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  receivedAmount:   "Received amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
  paidOutAmount:    "Paid out amount"
  files:            "Files"
  filename:         "Filename"
  category:         "Category"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  add:              "Add"
  documents:        "Documents"
  notFound:         "No documents found"
ru:
  title:            "Factoring invoice (status: {{ status }})"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  date:             "Date"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  advanceRate:      "Advance rate"
  advanceAmount:    "Advance amount"
  availableLimit:   "Available limit"
  payoutAmount:     "Payout amount"
  receivedAmount:   "Received amount"
  reserveAmount:    "Reserve amount"
  recourse:         "Recourse"
  paidOutAmount:    "Paid out amount"
  files:            "Files"
  filename:         "Filename"
  category:         "Category"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  add:              "Add"
  documents:        "Documents"
  notFound:         "No documents found"
</i18n>
