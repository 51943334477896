<template lang="pug">
.factoring-invoice-list.animated.fadeIn
  .card
    nav.card-header
      b-nav(card-header tabs)
        b-nav-item(:to = '{ name: "FactoringContractList" }') {{ $t('menu:factoringContracts') }}
        b-nav-item(:to = '{ name: "FactoringInvoiceList" }') {{ $t('menu:factoringInvoices') }}
        b-nav-text.ml-auto.align-items-center.d-inline-flex
          span.toolbar
            button.btn.btn-link.py-0(@click = 'showNewInvoice = true') {{ $t('newLink') }}
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg-6
            range-input-date(
              :options     = 'rangeInputDateOptions'
              :start.sync  = 'searchParams.dateMin'
              :end.sync    = 'searchParams.dateMax'
              :column.sync = 'searchParams.dateColumn'
            )
        .row
          .form-group.col-lg-6
            range-input-text(
              :options     = 'rangeInputTextOptions'
              :start.sync  = 'searchParams.amountMin'
              :end.sync    = 'searchParams.amountMax'
              :column.sync = 'searchParams.amountColumn'
            )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model      = 'invoiceStatusSearchModel'
                :options     = 'invoiceStatusOptions'
                :placeholder = '$t("invoiceStatusesPlaceholder")'
                multiple
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model      = 'scheduleStatusSearchModel'
                :options     = 'scheduleStatusOptions'
                :placeholder = '$t("scheduleStatusesPlaceholder")'
                multiple
              )
      .my-2(v-if = 'importedInvoiceListTotal') {{ $t('searchResults', { results: importedInvoiceListTotal }) }}
      fi-table(
        :fields      = 'fields'
        :items       = 'importedInvoiceList'
        loader       = 'factoring:contract:invoices:imported:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          factoring-invoice-list-item.animated.fadeIn(
            v-for     = 'invoice in items'
            :key      = 'invoice.id'
            :invoice  = 'invoice'
          )
      fi-pagination(
        v-if    = 'importedInvoiceListPages > 1'
        :pages  = 'importedInvoiceListPages'
        v-model = 'pagination.page'
      )
  factoring-contract-invoice-new-modal(
    v-if             = 'showNewInvoice'
    v-model          = 'showNewInvoice'
    loader           = 'factoring:contract:invoice:create'
    :third-parties   = 'optionsFromThirdParties'
    :contracts       = 'contracts'
    :fee-configs     = 'feeConfigs'
    :processing-fee  = 'contractProcessingFee'
    @select-contract = 'selectContract'
    @search-contract = 'searchContracts'
    @submit          = 'addFactoringInvoice'
  )
</template>


<script>
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import FiPagination from '@/components/FiPagination'
import { mapActions, mapGetters, mapState } from 'vuex'
import FactoringInvoiceListItem from '@/views/factoring/FactoringInvoiceListItem'
import { customerName, getStatuesQuery } from '@/helpers'
import FiMultiselect from '@/components/FiMultiselect'
import FactoringContractInvoiceNewModal from '@/views/factoring/FactoringContractInvoiceNewModal'
import api from '@/api'

const searchParams = {
  criteria: '',
  dateColumn: 'dueDate',
  dateMin: null,
  dateMax: null,
  amountColumn: 'invoiceAmount',
  amountMin: null,
  amountMax: null,
  factoringInvoiceStatuses: [],
  schInvoiceStatuses: [],
  sort: 'contractId,desc'
}

export default {
  name: 'factoring-invoice-list',

  components: { FactoringContractInvoiceNewModal, FiMultiselect, FactoringInvoiceListItem, FiPagination, FiTable, FiSelect, RangeInputText, RangeInputDate },

  data: (vm) => ({
    showNewInvoice: false,
    contracts: [],
    searchParams: {
      ...searchParams,
      ...pick(vm.$route.query, Object.keys(searchParams)),
      factoringInvoiceStatuses: getStatuesQuery(vm.$route.query.factoringInvoiceStatuses),
      schInvoiceStatuses: getStatuesQuery(vm.$route.query.schInvoiceStatuses),
      searchQuery: vm.criteria
    },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('factoring', ['importedInvoiceList', 'importedInvoiceListPages', 'importedInvoiceListTotal', 'factoringThirdParties', 'factoringContract']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('products', ['optionsFromAllProducts']),
    ...mapState('products', ['feeConfigs']),
    invoiceStatusOptions () {
      return this.optionsFromClassifier('FactoringImportedInvoiceStatus', true)
    },
    invoiceStatusSearchModel: {
      get () {
        return this.searchParams.factoringInvoiceStatuses.map(searchValue => this.invoiceStatusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.invoiceStatusOptions.length) {
          this.searchParams.factoringInvoiceStatuses = []
        } else {
          this.searchParams.factoringInvoiceStatuses = values.map(({ value }) => value)
        }
      }
    },
    scheduleStatusOptions () {
      return this.optionsFromClassifier('LoanInvoiceStatus', true)
    },
    scheduleStatusSearchModel: {
      get () {
        return this.searchParams.schInvoiceStatuses.map(searchValue => this.scheduleStatusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.scheduleStatusOptions.length) {
          this.searchParams.schInvoiceStatuses = []
        } else {
          this.searchParams.schInvoiceStatuses = values.map(({ value }) => value)
        }
      }
    },
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    },
    rangeInputDateOptions () {
      return {
        dueDate: this.$t('dueDate'),
        paidOutDate: this.$t('paidOutDate'),
        deadline: this.$t('deadline')
      }
    },
    rangeInputTextOptions () {
      return {
        invoiceAmount: this.$t('invoiceAmount'),
        paidOutAmount: this.$t('paidOutAmount')
      }
    },
    fields () {
      return [
        {
          label: 'ID'
        },
        {
          key: 'invoiceNumber',
          label: this.$t('invoiceNumber')
        },
        {
          key: 'thirdParty',
          label: this.$t('thirdParty')
        },
        {
          key: 'contract',
          label: this.$t('contract')
        },
        {
          key: 'dueDate',
          label: this.$t('dueDate'),
          sort: 'dueDate'
        },
        {
          key: 'invoiceAmount',
          label: this.$t('invoiceAmount'),
          class: 'money'
        },
        {
          key: 'advanceAmount',
          label: this.$t('advanceAmount'),
          class: 'money'
        },
        {
          key: 'paidOutAmount',
          label: this.$t('paidOutAmount'),
          class: 'money'
        },
        {
          key: 'paidOutDate',
          label: this.$t('paidOutDate')
        },
        {
          key: 'factoringInvoiceStatus',
          label: this.$t('factoringInvoiceStatus')
        },
        {
          key: 'reserve',
          label: this.$t('reserve'),
          class: 'money'
        },
        {
          key: 'receivedAmount',
          label: this.$t('receivedAmount'),
          class: 'money'
        },
        {
          key: 'receivableSum',
          label: this.$t('receivableSum'),
          class: 'money'
        },
        {
          key: 'deadline',
          label: this.$t('deadline')
        },
        {
          key: 'paid',
          label: this.$t('paid'),
          class: 'money'
        },
        {
          key: 'paidDate',
          label: this.$t('paidDate')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    },
    searchFilter () {
      const filter = { ...this.searchParams }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      if (!(filter.amountMin || filter.amountMax)) {
        filter.amountColumn = null
      }
      return filter
    },
    optionsFromThirdParties () {
      return this.factoringThirdParties?.map(thirdParty => ({
        value: thirdParty.id,
        text: `${customerName(thirdParty.customer)} (${thirdParty.customer.idCode})`
      }))
    },
    contractProcessingFee () {
      return this.factoringContract?.term?.fees?.find(({ feeType }) => feeType.code === 'PROCESSING')
    }
  },

  watch: {
    'pagination.page': 'search',
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    '$route.query': {
      handler (query) {
        this.loadFactoringImportedInvoices({ ...this.searchQuery, ...query })
      },
      deep: true,
      immediate: true
    }
  },

  async created () {
    this.loadProducts({ group: 'FACTORING' })
    await this.loadFactoringInvoiceClassifiers()
    this.loadFeeConfig({ group: 'FACTORING' })
  },

  methods: {
    ...mapActions('factoring', [
      'loadFactoringImportedInvoices',
      'createFactoringInvoice',
      'loadFactoringThirdParties',
      'loadFactoringContract'
    ]),
    ...mapActions('classifiers', ['loadFactoringInvoiceClassifiers']),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    ),

    async addFactoringInvoice ({ invoiceData, resourceData, files, done }) {
      await this.createFactoringInvoice({ invoiceData, resourceData, files })
      done && done()
      this.search()
    },
    async searchContracts ({ query }) {
      this.contracts = (await api.getFactoringContracts({ filters: { criteria: query } })).data.content
    },
    async selectContract ({ contractId }) {
      this.loadFactoringThirdParties({ contractId })
      this.loadFactoringContract({ id: contractId })
    }
  }
}
</script>


<i18n>
en:
  newLink:                     "+ New factoring invoice"
  searchPlaceholder:           "Search by third-party name"
  invoiceStatusesPlaceholder:  "All invoice statuses"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  thirdParty:                  "Third-party"
  contract:                    "Contract id"
  notFound:                    "Contracts not found"
  dueDate:                     "Due date"
  invoiceAmount:               "Invoice amount"
  advanceAmount:               "Advance amount"
  paidOutAmount:               "Paid out amount"
  paidOutDate:                 "Paid out date"
  factoringInvoiceStatus:      "Invoice status"
  reserve:                     "Reserve amount"
  receivedAmount:              "Received amount"
  receivableSum:               "Receivable sum"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
  recourse:                    "Recourse"
  searchResults:               "Search results: {{ results }}"
et:
  newLink:                     "+ New factoring invoice"
  searchPlaceholder:           "Search by third-party name"
  invoiceStatusesPlaceholder:  "All invoice statuses"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  thirdParty:                  "Third-party"
  contract:                    "Contract id"
  notFound:                    "Contracts not found"
  dueDate:                     "Due date"
  invoiceAmount:               "Invoice amount"
  advanceAmount:               "Advance amount"
  paidOutAmount:               "Paid out amount"
  paidOutDate:                 "Paid out date"
  factoringInvoiceStatus:      "Invoice status"
  reserve:                     "Reserve amount"
  receivedAmount:              "Received amount"
  receivableSum:               "Receivable sum"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
  recourse:                    "Recourse"
  searchResults:               "Search results: {{ results }}"
ru:
  newLink:                     "+ New factoring invoice"
  searchPlaceholder:           "Search by third-party name"
  invoiceStatusesPlaceholder:  "All invoice statuses"
  scheduleStatusesPlaceholder: "All schedule statuses"
  invoiceNumber:               "Invoice #"
  thirdParty:                  "Third-party"
  contract:                    "Contract id"
  notFound:                    "Contracts not found"
  dueDate:                     "Due date"
  invoiceAmount:               "Invoice amount"
  advanceAmount:               "Advance amount"
  paidOutAmount:               "Paid out amount"
  paidOutDate:                 "Paid out date"
  factoringInvoiceStatus:      "Invoice status"
  reserve:                     "Reserve amount"
  receivedAmount:              "Received amount"
  receivableSum:               "Receivable sum"
  deadline:                    "Deadline"
  paid:                        "Paid amount"
  paidDate:                    "Paid date"
  status:                      "Schedule status"
  recourse:                    "Recourse"
  searchResults:               "Search results: {{ results }}"
</i18n>
