<template lang="pug">
.factoring-contract-agreement-terms.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
      v-if           = '!editing && isEditable'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form.animated.fadeIn(
      v-if            = 'editing'
      @submit.prevent = 'saveChanges'
      novalidate
    )
      .form-row
        .col-lg-6
          fi-form-field(:label = '$t("reference")')
            .form-control-sm.form-control-plaintext {{ factoringAccount.referenceNumber }}
          fi-form-field(:label = '$t("startDate")')
            fi-datepicker(v-model = 'termsData.periodStart')
          fi-form-field(:label = '$t("endDate")')
            fi-datepicker(v-model = 'termsData.periodEnd')
          fi-form-field(:label = '$t("contractLimit")')
            fi-money-input.form-control-sm(v-model = 'termsData.contractLimit')
          fi-form-field(:label = '$t("advanceRate")')
            percent-input.form-control-sm(v-model = 'termsData.advanceRate')
          fi-form-field(:label = '$t("invoiceLength")')
            .form-row
              .input-group.col.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:min') }}
                input.form-control.form-control-sm.animated.fadeIn(
                  type           = 'text'
                  v-model.number = 'termsData.invoiceLengthMin'
                )
              .input-group.col.input-group-sm
                .input-group-prepend
                  span.input-group-text {{ $t('common:max') }}
                input.form-control.form-control-sm.animated.fadeIn(
                  type           = 'text'
                  v-model.number = 'termsData.invoiceLengthMax'
                )
          fi-form-field(:label = '$t("interestRate")')
            application-factoring-interest-rate-item(
              v-for                 = '(interestRate, index) in termsData.invoiceLengthInterestRates'
              :key                  = 'index'
              :interest-rate        = 'interestRate'
              :day-count-convention = 'term.dayCountConvention'
              @input                = 'updateInterestRate($event, index)'
            )
              .col-2.px-1
                button.btn.btn-danger.btn-sm(
                  v-if           = 'index'
                  @click.prevent = 'updateInterestRate(undefined, index)'
                ): i.far.fa-trash-alt.fa-lg
                button.btn.btn-primary.btn-sm(
                  v-else
                  @click.prevent = 'addInterestRate'
                ): i.far.fa-plus-square.fa-lg
        .col-lg-6
          fi-form-field(:label = '$t("penaltyRate")')
            percent-input.form-control.form-control-sm(v-model = 'termsData.penaltyRate')
          fi-form-field(
            v-for  = '(fee, index) in termsData.fees'
            :key   = 'index'
            :label = 'classifierByCode("feeTypeEntity", fee.feeType).human'
            :disabled = '!isFeeEditable(fee)'
          )
            .form-row
              .col: fi-select(
                v-model.number = 'fee.feeCalculationType'
                :options       = 'feeCalculationTypeOptions[fee.feeType]'
                required
                sm
              )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'feePercentage(fee.feeCalculationType)'
                  v-model.number = 'fee.feeAmount'
                  type           = 'text'
                )
                fi-money-input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'fee.feeAmount'
                  type           = 'text'
                )
          fi-form-field(:label = '$t("rightOfRecourse")')
            fi-switch(v-model = 'termsData.rightOfRecourse')
          fi-form-field(:label = '$t("specialTerms")')
            fi-rich-text-editor(v-model.trim = 'termsData.specialTerms')
      button.btn.btn-primary.mr-sm-2(
        :disabled = 'saving'
        type      = 'submit'
      )
        | {{ $t('common:save') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
      button.btn.btn-secondary(
        :disabled      = 'saving'
        @click.prevent = 'toggleEditing'
      ) {{ $t('common:cancel') }}
    .row(v-else)
      .col-lg-6
        fi-data-field(:label = '$t("reference")') {{ factoringAccount.referenceNumber }}
        fi-data-field(:label = '$t("startDate")') {{ $t('common:formatDate', { value: term.periodStart }) }}
        fi-data-field(:label = '$t("endDate")') {{ $t('common:formatDate', { value: term.periodEnd }) }}
        fi-data-field(:label = '$t("contractLimit")') {{ term.contractLimit | money }}
        fi-data-field(:label = '$t("advanceRate")') {{ term.advanceRate | percent}}
        fi-data-field(:label = '$t("invoiceLength")')
          .row
            .col {{ $t('common:min') }} {{ term.invoiceLengthMin }}
            .col {{ $t('common:max') }} {{ term.invoiceLengthMax }}
        fi-data-field(:label = '$t("interestRate")')
          .row.mb-3
            .col {{ classifierByCode(term.dayCountConvention.classifier, term.dayCountConvention.code).human }}
          .row(v-for = 'interestRate in termsData.invoiceLengthInterestRates')
            .col-4 {{ interestRate.invoiceLength }} {{ $t('days') }}
            .col-4 {{ interestRate.periodInterestRate | percent }}
            .col-4 {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
      .col-lg-6
        fi-data-field(:label = '$t("penaltyRate")')
          .row
            .col {{ term.penaltyRate | percent }}
            .col {{ classifierByCode(term.penaltyDayCountConvention.classifier, term.penaltyDayCountConvention.code).human }}
        fi-data-field(
          v-for  = '(fee, index) in term.fees'
          :key   = 'index'
          :label = 'classifierByCode(fee.feeType.classifier, fee.feeType.code).human'
        )
          template(v-if = 'feePercentage(fee.feeCalculationType.code)') {{ fee.feeAmount | percent }}
          template(v-else) {{ fee.feeAmount | money }}
        fi-data-field(:label = '$t("rightOfRecourse")')
          fi-switch(
            :value = 'term.rightOfRecourse'
            disabled
          )
        fi-data-field(:label = '$t("specialTerms")'): span(v-html = 'sanitizedText')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDataField from '@/components/FiDataField'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isFeePercentage } from '@/mixins'
import FiSwitch from '@/components/FiSwitch'
import sanitizeHtml from 'sanitize-html'
import FiDatepicker from '@/components/FiDatepicker'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import ApplicationFactoringInterestRateItem
  from '@/views/applications/ApplicationFactoring/ApplicationFactoringInterestRateItem'
import FiSelect from '@/components/FiSelect'
import { calculationTypes } from '@/helpers'
import BigNumber from 'bignumber.js'

const dayCountConventionDays = {
  E30_360: 360,
  E30_365: 365,
  ACT_360: 360,
  ACT_365: 365
}

export default {
  name: 'factoring-contract-agreement-terms',

  components: { FiSelect, ApplicationFactoringInterestRateItem, FiRichTextEditor, PercentInput, FiMoneyInput, FiDatepicker, FiSwitch, FiDataField, FiFormField },

  mixins: [isFeePercentage],

  props: {
    term: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data: () => ({
    editing: false,
    termsData: {
      advanceRate: null,
      contractLimit: null,
      periodStart: null,
      periodEnd: null,
      invoiceLengthMax: null,
      invoiceLengthMin: null,
      invoiceLengthInterestRates: [],
      fees: [],
      penaltyRate: null,
      rightOfRecourse: true,
      specialTerms: null
    }
  }),

  computed: {
    ...mapState('products', ['feeConfigs']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByCode', 'classifierList']),
    ...mapGetters('factoring', ['factoringProductAttributes', 'factoringProductFees']),
    ...mapState('factoring', ['factoringContract', 'factoringAccount']),
    saving () {
      return this.$vueLoading.isLoading(['factoring:contract:save', `factoring:contract:term:${this.term.id}:save`])
    },
    sanitizedText () {
      return sanitizeHtml(this.term.specialTerms || '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
    },
    feeCalculationTypeOptions () {
      return this.term.fees.reduce((res, fee) => {
        return {
          ...res,
          [fee.feeType.code]: calculationTypes(fee, this.feeConfigs, this.classifierList(fee.feeCalculationType.classifier))
        }
      }, {})
    }
  },

  watch: {
    term () {
      this.toggleEditing({ status: false })
    }
  },

  created () {
    this.resetData()
    this.loadFactoringAccount({ accountId: this.factoringContract.prepaymentAccountId })
  },

  methods: {
    ...mapActions('factoring', ['saveFactoringContractTerm', 'loadFactoringAccount']),
    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.termsData = {
        ...cloneDeep(pick(this.term, Object.keys(this.termsData))),
        fees: this.term.fees.map(({ feeType, feeCalculationType, feeAmount }) => ({
          feeAmount,
          feeType: feeType.code,
          feeCalculationType: feeCalculationType.code
        }))
      }
      const termsDataNew = this.termsData.invoiceLengthInterestRates.map((term) => this.resetInvoiceLengthInterestRates(term))
      this.termsData.invoiceLengthInterestRates = termsDataNew
    },
    resetInvoiceLengthInterestRates (term) {
      const newTerm = term
      const dayRate = new BigNumber(term.yearlyInterestRate).dividedBy(dayCountConventionDays[this.term.dayCountConvention?.code]).toPrecision(3)
      if (term.invoiceLength) {
        newTerm.periodInterestRate = new BigNumber(dayRate)
          .multipliedBy(term.invoiceLength)
          .toPrecision(3)
      }
      return newTerm
    },
    toggleEditing ({ status = !this.editing } = {}) {
      if (status) {
        this.resetData()
      }
      this.editing = status
    },
    async saveChanges () {
      try {
        await this.saveFactoringContractTerm({
          contractId: this.$route.params.id,
          termId: this.term.id,
          termData: this.termsData
        })
        this.toggleEditing({ status: false })
      } catch (e) {}
    },
    updateInterestRate (value, index) {
      if (typeof value === 'undefined') {
        this.termsData.invoiceLengthInterestRates.splice(index, 1)
      } else {
        this.termsData.invoiceLengthInterestRates.splice(index, 1, value)
      }
    },
    addInterestRate () {
      this.termsData.invoiceLengthInterestRates.push({
        invoiceLength: this.factoringProductAttributes?.INVOICE_LENGTH_IN_DAYS?.defaultValue,
        periodInterestRate: undefined,
        yearlyInterestRate: this.factoringProductAttributes?.INTEREST_RATE?.defaultValue
      })
    },
    isFeeEditable ({ feeType }) {
      switch (feeType) {
        case 'AGREEMENT':
          return /inserted/i.test(this.factoringContract.status.code)
        default:
          return this.factoringProductFees[feeType]?.feeOverride
      }
    }
  }
}
</script>


<i18n>
en:
  title:           "Factoring agreement terms"
  signDate:        "Signing date"
  reference:       "Reference"
  contractLimit:   "Contract limit"
  advanceRate:     "Advance rate"
  startDate:       "Start date"
  endDate:         "End date"
  penaltyRate:     "Penalty interest"
  rightOfRecourse: "Right of recourse"
  invoiceLength:   "Invoice length"
  interestRate:    "Interest rate"
  days:            "days"
  yearly:          "yearly"
  specialTerms:    "Special terms"
et:
  title:           "Factoring agreement terms"
  signDate:        "Signing date"
  reference:       "Reference"
  contractLimit:   "Contract limit"
  advanceRate:     "Advance rate"
  startDate:       "Start date"
  endDate:         "End date"
  penaltyRate:     "Penalty interest"
  rightOfRecourse: "Right of recourse"
  invoiceLength:   "Invoice length"
  interestRate:    "Interest rate"
  days:            "days"
  yearly:          "yearly"
  specialTerms:    "Special terms"
ru:
  title:           "Factoring agreement terms"
  signDate:        "Signing date"
  reference:       "Reference"
  contractLimit:   "Contract limit"
  advanceRate:     "Advance rate"
  startDate:       "Start date"
  endDate:         "End date"
  penaltyRate:     "Penalty interest"
  rightOfRecourse: "Right of recourse"
  invoiceLength:   "Invoice length"
  interestRate:    "Interest rate"
  days:            "days"
  yearly:          "yearly"
  specialTerms:    "Special terms"
</i18n>
