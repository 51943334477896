<template lang="pug">
b-modal.factoring-contract-invoice-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'xl'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    .row
      .col-lg-6
        fi-form-field(
          v-if   = '!contractId'
          :label = '$t("contract")'
        )
          fi-multiselect(
            v-model          = 'selectedContract'
            :options         = 'contracts'
            :search-callback = 'searchContracts'
            trackBy          = 'factoringId'
            label            = 'agreementNumber'
            sm
          )
        fi-form-field(:label = '$t("thirdParty")')
          fi-select(
            v-model  = 'invoiceData.thirdPartyId'
            :options = 'thirdParties'
            sm
          )
        fi-form-field(:label = '$t("invoiceNr")')
          input.form-control.form-control-sm(v-model.trim = 'invoiceData.invoiceNumber')
        fi-form-field(:label = '$t("amount")')
          fi-money-input.form-control-sm(v-model = 'invoiceData.amount')
        fi-form-field(
          :label = 'classifierByName("feeTypeEntity", invoiceData.processingFee.feeType).human'
          disabled
        )
          .form-row
            .col
              fi-select(
                v-model = 'invoiceData.processingFee.feeCalculationType'
                :options = 'feeCalculationTypeOptions'
                required
                sm
              )
            .col
              percent-input.form-control-sm(
                v-if = 'percentageProcessingFee'
                v-model = 'invoiceData.processingFee.feeAmount'
              )
              input.form-control-sm.form-control(
                v-else
                v-model = 'invoiceData.processingFee.feeAmount'
              )
      .col-lg-6
        fi-form-field(:label = '$t("invoiceDate")')
          fi-datepicker(
            v-model = 'invoiceData.invoiceDate'
            sm
          )
        fi-form-field(:label = '$t("dueDate")')
          fi-datepicker(
            v-model = 'invoiceData.dueDate'
            :min-date = 'invoiceData.invoiceDate'
            sm
          )
        fi-form-field(:label = '$t("period")') {{ invoicePeriod }}
    h5.mt-4.border-bottom.pb-2 {{ $t('uploadDocument') }}
    fi-form-field(
      :label     = '$t("files")'
      label-cols = 2
    )
      .form-row
        .col-auto
          .input-group
            .input-group-prepend
              span.input-group-text
                i.fa.fa-file-alt
            fi-select(
              v-model.number = 'resourceData.documentCategoryId'
              :options       = 'optionsFromClassifier("DocumentCategoryType")'
              required
              sm
            )
        .col
          fi-file-input(
            v-model = 'files'
            size    = 'sm'
            multiple
          )
    fi-form-field(
      :label     = '$t("description")'
      label-cols = 2
    )
      textarea.form-control(v-model = 'resourceData.comment')
    fi-form-field(
      :label     = '$t("visibleForPortal")'
      label-cols = 2
    )
      fi-switch(v-model = 'resourceData.visibleForPortal')
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
  fi-confirm-modal(
    v-model      = 'showConfirmModal'
    :ok-callback = '() => onSubmit({ confirm: true })'
    :message     = '$t("confirmMessage")'
    :loader       = 'loader'
  )
</template>


<script>
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import { mapGetters } from 'vuex'
import FiDatepicker from '@/components/FiDatepicker'
import FiFileInput from '@/components/FiFileInput'
import FiSwitch from '@/components/FiSwitch'
import { feePercentage } from '@/helpers'
import FiMultiselect from '@/components/FiMultiselect'
import defaults from 'lodash/defaults'
import FiConfirmModal from '@/components/FiConfirmModal'

export default {
  name: 'factoring-contract-invoice-new-modal',

  components: { FiConfirmModal, FiMultiselect, FiSwitch, FiFileInput, FiDatepicker, PercentInput, FiMoneyInput, FiFormField, FiSelect, FiModal },

  props: {
    loader: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    thirdParties: {
      type: Array,
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    },
    contractId: {
      type: [Number, String],
      default: null
    },
    processingFee: {
      type: Object,
      default: () => ({})
    },
    contracts: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    showConfirmModal: false,
    contract: null,
    invoiceData: {
      contractId: vm.contractId,
      thirdPartyId: null,
      processingFee: {
        feeType: 'PROCESSING',
        feeAmount: undefined,
        feeCalculationType: undefined
      },
      invoiceNumber: '',
      amount: null,
      invoiceDate: null,
      dueDate: null
    },
    files: [],
    resourceData: {
      comment: null,
      documentCategoryId: '',
      visibleForPortal: false
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName']),
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    percentageProcessingFee () {
      return feePercentage(this.invoiceData.processingFee.feeCalculationType)
    },
    feeCalculationTypeOptions () {
      const feeCalculationTypePossible = this.feeConfigs?.[this.invoiceData?.processingFee?.feeType]
      return this.optionsFromClassifier('feeCalculationType', true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },
    invoicePeriod () {
      const { dueDate, invoiceDate } = this.invoiceData
      if (!invoiceDate || !dueDate) return
      return this.$moment.duration(this.$moment(invoiceDate).diff(this.$moment(dueDate ?? ''))).humanize({ d: 90 })
    },
    selectedContract: {
      get () {
        return this.contract
      },
      set (contract) {
        this.contract = contract
        this.invoiceData.contractId = contract.factoringId
        this.$emit('select-contract', { contractId: contract.factoringId })
      }
    }
  },

  watch: {
    processingFee: {
      handler (fee) {
        const { feeCalculationType, feeAmount, feeType } = fee
        this.invoiceData.processingFee = defaults(this.invoiceData.processingFee, {
          feeCalculationType: feeCalculationType?.code,
          feeAmount,
          feeType: feeType?.code
        })
      },
      immediate: true
    }
  },

  methods: {
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit ({ confirm = Boolean(this.files.length) }) {
      if (confirm) {
        const { files, resourceData, invoiceData } = this
        this.$emit('submit', {
          files,
          resourceData,
          invoiceData,
          done: () => {
            this.$refs.modal.hide()
            this.showConfirmModal = false
          }
        })
      } else {
        this.showConfirmModal = true
      }
    },
    resetData () {
      this.resourceData.documentCategoryId = this.classifierByName('DocumentCategoryType', 'FACTORING_INVOICE')?.id
    },
    searchContracts ({ query }) {
      this.$emit('search-contract', query)
    }
  }
}
</script>


<i18n>
en:
  title:            "New invoice"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  uploadDocument:   "Upload document"
  files:            "Files"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  confirmMessage:    "Do you want to continue without adding an attachment?"
et:
  title:            "New invoice"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  uploadDocument:   "Upload document"
  files:            "Files"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  confirmMessage:    "Do you want to continue without adding an attachment?"
ru:
  title:            "New invoice"
  thirdParty:       "Third-party"
  invoiceNr:        "Invoice #"
  amount:           "Amount"
  invoiceDate:      "Invoice date"
  dueDate:          "Due date"
  period:           "Period"
  uploadDocument:   "Upload document"
  files:            "Files"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
  confirmMessage:    "Do you want to continue without adding an attachment?"
</i18n>
