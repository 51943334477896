<template lang="pug">
fi-table.factoring-contract-overview-invoices-list(
  :fields      = 'fields'
  :items       = 'invoices'
  :empty-label = '$t("notFound")'
  loader       = 'factoring:contract:invoices:imported:fetch'
  responsive
)
  template(#default = '{ items }')
    factoring-contract-overview-invoices-list-item(
      v-for    = 'invoice in items'
      :key     = 'invoice.id'
      :invoice = 'invoice'
    )
  template(#custom-footer)
    tr.font-weight-bold
      td(colspan=3)
      td.money {{ totalValues.invoiceAmount | money }}
      td.money {{ totalValues.advanceAmount | money }}
      td.money {{ totalValues.paidOutAmount | money }}
      td
      td.money {{ totalValues.receivableSum | money }}
      td
      td.money {{ totalValues.paid | money }}
      td.money {{ totalValues.reserve | money }}
</template>


<script>
import FiTable from '@/components/FiTable'
import FactoringContractOverviewInvoicesListItem from '@/views/factoring/FactoringContractOverviewInvoicesListItem'
import { mapActions } from 'vuex'

export default {
  name: 'factoring-contract-overview-invoices-list',

  components: { FactoringContractOverviewInvoicesListItem, FiTable },

  props: {
    invoices: {
      type: Array,
      required: true
    }
  },

  computed: {
    totalValues () {
      return this.invoices.reduce((res, { invoiceAmount = 0, advanceAmount = 0, paidOutAmount = 0, receivableSum = 0, paid = 0, reserve = 0 }) => ({
        invoiceAmount: (res.invoiceAmount ?? 0) + invoiceAmount,
        advanceAmount: (res.advanceAmount ?? 0) + advanceAmount,
        paidOutAmount: (res.paidOutAmount ?? 0) + paidOutAmount,
        receivableSum: (res.receivableSum ?? 0) + receivableSum,
        paid: (res.paid ?? 0) + paid,
        reserve: (res.reserve ?? 0) + reserve
      }), {})
    },
    fields () {
      return [
        {
          label: this.$t('invoiceNumber'),
          class: 'text-wrap'
        },
        {
          label: this.$t('thirdParty'),
          class: 'text-wrap'
        },
        {
          label: this.$t('dueDate'),
          class: 'text-wrap'
        },
        {
          label: this.$t('amount'),
          class: 'text-wrap money'
        },
        {
          label: this.$t('amountAdvance'),
          class: 'text-wrap money'
        },
        {
          label: this.$t('amountPaidout'),
          class: 'text-wrap money'
        },
        {
          label: this.$t('status')
        },
        {
          label: this.$t('amountReceivable'),
          class: 'text-wrap money'
        },
        {
          label: this.$t('deadline')
        },
        {
          label: this.$t('receivablePaid'),
          class: 'money'
        },
        {
          label: this.$t('amountReserved'),
          class: 'money'
        }
      ]
    }
  },

  created () {
    this.loadFactoringImportedInvoices({ contractId: this.$route.params.id })
  },

  methods: mapActions('factoring', ['loadFactoringImportedInvoices'])
}
</script>


<i18n>
en:
  invoiceNumber:      "Invoice #"
  thirdParty:         "Third party"
  dueDate:            "Due date"
  amount:             "Invoice amount"
  amountAdvance:      "Advance amount"
  amountPaidout:      "Paid out amount"
  datePaidout:        "Paid out date"
  status:             "Status"
  amountReceivable:   "Receivable amount"
  deadline:           "Deadline"
  receivablePaid:     "Paid"
  amountReserved:     "Reserve"
  notFound:           "Invoices not found"
et:
  invoiceNumber:      "Invoice #"
  thirdParty:         "Third party"
  dueDate:            "Due date"
  amount:             "Invoice amount"
  amountAdvance:      "Advance amount"
  amountPaidout:      "Paid out amount"
  datePaidout:        "Paid out date"
  status:             "Status"
  amountReceivable:   "Receivable amount"
  deadline:           "Deadline"
  receivablePaid:     "Paid"
  amountReserved:     "Reserve"
  notFound:           "Invoices not found"
ru:
  invoiceNumber:      "Invoice #"
  thirdParty:         "Third party"
  dueDate:            "Due date"
  amount:             "Invoice amount"
  amountAdvance:      "Advance amount"
  amountPaidout:      "Paid out amount"
  datePaidout:        "Paid out date"
  status:             "Status"
  amountReceivable:   "Receivable amount"
  deadline:           "Deadline"
  receivablePaid:     "Paid"
  amountReserved:     "Reserve"
  notFound:           "Invoices not found"
</i18n>
