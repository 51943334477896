<template lang="pug">
tr.factoring-contract-third-party-list-item
  td {{ thirdParty.id }}
  td
    v-loading(:loader = '`customers:${thirdParty.customerId}:fetch`')
      .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
      router-link(:to = '{ name: "Customer", params: { id: thirdParty.customerId } }') {{ customerName(customer) }}
  td
    router-link(
      v-if = 'mainContact.id'
      :to  = '{ name: "Customer", params: { id: mainContact.id } }'
    ) {{ customerName(mainContact) }}
  td {{ $t('common:formatDate', { value: thirdParty.startDate }) }}
  td {{ $t('common:formatDate', { value: thirdParty.endDate }) }}
  td.money {{ thirdParty.limit | money }}
  td.money {{ thirdParty.availableLimit | money }}
  td {{ thirdParty.advanceRate | percent }}
  td
    .row
      .col {{ thirdParty.invoiceLengthMin }}
      .col {{ thirdParty.invoiceLengthMax }}
  td
    button.btn.btn-link.py-0(:id = '`${invoiceTermId}_interest`')
      i.fa-percent.fas.text-primary
  td
    router-link(:to = '{ name: "CustomerPaymentInfo", params: { id: thirdParty.customerId } }') {{ thirdParty.referenceNumber }}
  td {{ $t('common:formatDate', { value: thirdParty.updatedAt }) }}
  td
    button.btn.btn-link.py-0(
      v-if = 'thirdParty.notes'
      :id  = '`${invoiceTermId}_notes`'
    )
      i.fas.text-primary.fa-comment-alt
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
  td
    button.btn.btn-link.fa-pull-right.animated.fadeIn.py-0(
      v-if           = 'editable'
      :title         = '$t("common:edit")'
      @click.prevent = 'editing = true'
    ): i.far.fa-edit.fa-lg
  b-popover(
    :target   = '`${invoiceTermId}_interest`'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row.text-nowrap(v-for = 'interestRate in thirdParty.invoiceLengthInterestRates')
      .col {{ interestRate.invoiceLength }} {{ $t('days') }}
      .col {{ interestRate.periodInterestRate | percent }}
      .col {{ interestRate.yearlyInterestRate | percent }} {{ $t('yearly') }}
  b-popover(
    :target   = '`${invoiceTermId}_notes`'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row
      .col {{ thirdParty.notes }}
  factoring-contract-third-party-modal(
    v-model             = 'editing'
    :third-party        = 'thirdParty'
    :product-attributes = 'productAttributes'
    :loader             = 'loader'
    :source-data        = 'sourceData'
    @submit             = '$listeners.edit'
  )
</template>


<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import FactoringContractThirdPartyModal from '@/views/factoring/FactoringContractThirdPartyModal'
import BigNumber from 'bignumber.js'

const dayCountConventionDays = {
  E30_360: 360,
  E30_365: 365,
  ACT_360: 360,
  ACT_365: 365
}

const COLOR_MAP = {
  ACTIVE: 'primary',
  INACTIVE: 'secondary'
}

export default {
  name: 'factoring-contract-third-party-list-item',

  components: { FactoringContractThirdPartyModal },

  props: {
    thirdParty: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    productAttributes: {
      type: Object,
      default: () => ({})
    },
    sourceData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editing: false,
    customer: null
  }),

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierById']),
    mainContact () {
      return this.customer?.referredRelations?.find(({ mainContact, active }) => mainContact && active)?.customer ?? this.customer ?? {}
    },
    status () {
      return this.classifierById('thirdPartyStatus', this.thirdParty.status?.code)
    },
    statusClass () {
      return COLOR_MAP[this.thirdParty.status?.code]
    },
    loader () {
      return this.$vueLoading.isLoading(`factoring:contract:thirdParty:${this.thirdParty.id}:save`)
    },
    invoiceTermId () {
      return `${this.thirdParty.id}_invoiceTerms`
    }
  },

  async created () {
    const { data: customer } = await api.getCustomer({ id: this.thirdParty.customerId })
    this.customer = customer
    const termsDataNew = this.thirdParty.invoiceLengthInterestRates.map((term) => this.resetInvoiceLengthInterestRates(term))
    this.thirdParty.invoiceLengthInterestRates = termsDataNew
  },
  methods: {
    resetInvoiceLengthInterestRates (term) {
      const newTerm = term
      const dayRate = new BigNumber(term.yearlyInterestRate).dividedBy(dayCountConventionDays[this.sourceData.dayCountConvention?.code]).toPrecision(3)
      if (term.invoiceLength) {
        newTerm.periodInterestRate = new BigNumber(dayRate)
          .multipliedBy(term.invoiceLength)
          .toPrecision(3)
      }
      return newTerm
    }
  }
}
</script>

<style scoped>

</style>
